import React from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { Container, Button, Row, Col } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import EditAssetItemTag from "../../views/assets/EditAssetItemTag";
import { connect } from "react-redux";
import AssetsService from "../../service/AssetsService";
import AllItemFilter from "commonComponents/itemDateFilter";
import AllFilter from "commonComponents/ItemFilter";
import { ItemBulkAction } from "../../commonComponents/ItemDropDown";
import AddAssetItemTag from "./AddAssetItemTag";
import { getDateTime, getTime, getDateColor } from "commonMethod/common";
import Pagination from "commonComponents/Pagination";
import ProductServices from "../../service/CommonServices";
import { Button as DxButton } from "devextreme-react/button";
import { jsPDF } from "jspdf";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import DataGrid, {
  Scrolling,
  ColumnChooser,
  LoadPanel,
  Selection,
  Pager,
  Column,
  Paging,
  StateStoring,
  Toolbar,
  Item,
  HeaderFilter,
  SearchPanel,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterBuilderPopup,
  Summary,
  GroupItem,
} from "devextreme-react/data-grid";

import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { exportDataGrid } from "devextreme/excel_exporter";

const allowedPageSizes = [5, 10, 20, 25, 50, "all"];
const exportFormats = ["pdf", "xlsx"];
const filterBuilderPopupPosition = {
  of: window,
  at: "top",
  my: "top",
  offset: { y: 10 },
};
const filterPanelTexts = {
  clearFilter: "CLEAR FILTER"
};
class AssetItemList extends React.Component {
  constructor(props) {
    super(props);
    this.listItemDataGrid = React.createRef();
    this.state = {
      pageSize: 20,
      pageIndex: 0,
      itemTagId: undefined,
      show: false,
      editShow: false,
      searchKeyword: "",
      dropdownOpen: false,
      bulkActionOpen: false,
      selectedItem: "",
      selectedKey: "",
      selectStartDate: "",
      selectEndDate: "",
      itemsArray: [],
      selectedIds: [],
      isMultiSelected: false,

      displayMode: "adaptive",
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
    };

    this.onRowDblClick = this.onRowDblClick.bind(this);
  }

  toggleFilter = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  toggleBulk = () => {
    this.setState({ bulkActionOpen: !this.state.bulkActionOpen });
  };

  getAssetItems = async (params = {}) => {
    const { pageSize, pageIndex } = this.state;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: "itemEPC",
      sortOrder: "ASC",
      id: this.props.asset_id,
      productType: "asset",
      ...params,
    };

    await this.props.getAssetItemTagList(obj);
    this.setState({ selectedIds: [] });
  };
  /**
   * Perform network request to get AssetItemList data
   */
  componentDidMount() {
    this.getAssetItems();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.asset_id !== prevProps.asset_id) {
      this.getAssetItems();
    }
  }

  createAndReturnCheckBox = () => {
    return this.props.assetItemTags?.length > 0
      ? this.props.assetItemTags.map((data) => {
          data["check"] = (
            <div style={{ textAlign: "center" }}>
              <input
                type="checkbox"
                name={data.GUID}
                checked={this.state.selectedIds.includes(data.GUID)}
                onChange={(event) => this.handleClickGroup(data.GUID)}
              />
            </div>
          );
          return data;
        })
      : [];
  };

  // Filter the data based on user search
  getPosts = () => {
    let { searchKeyword } = this.props;
    const list = this.createAndReturnCheckBox();
    const filteredList =
      searchKeyword && searchKeyword !== "" && list.length > 0
        ? list.filter((post) => {
            return (
              post.itemEPC
                .toLowerCase()
                .startsWith(searchKeyword.toLowerCase()) ||
              post.serialNumber
                .toLowerCase()
                .startsWith(searchKeyword.toLowerCase())
            );
          })
        : list;
    this.Assets = filteredList;
    return filteredList;
  };

  handleClickGroup = (id) => {
    let list = [...this.state.selectedIds];
    const isPresent = list.includes(id);
    let ids = [];
    if (isPresent) {
      const index = list.indexOf(id);
      list.splice(index, 1);
    } else {
      list.push(id);
    }

    if (list.length !== this.props.assetItemTags.length) {
      //   this.state.getMultiSelected(false);
      this.setState({ isMultiSelected: false });
    } else {
      //   this.state.getMultiSelected(true);
      this.setState({ isMultiSelected: true });
    }

    this.setState({ selectedIds: list });
  };

  handleMultiSelect = () => {
    const { pageIndex, pageSize, isMultiSelected, selectedIds } = this.state;
    let ids = [];
    if (isMultiSelected) {
      ids = [];
    } else {
      const list = this.Assets.slice(
        pageSize * pageIndex,
        pageSize * (pageIndex + 1)
      );
      ids = list.map((items) => {
        return items.GUID;
      });
    }
    this.setState({ isMultiSelected: !isMultiSelected, selectedIds: ids });
    // this.props.getMultiSelected(!this.props.isMultiSelectedProps);
    // this.props.getSelectedIds(ids);
  };

  toggleModal = () => {
    this.setState({ show: !this.state.show });
  };

  toggleEditModal = () => {
    this.setState({ editShow: !this.state.editShow });
  };

  /**
   *  Return the selected pageSize
   * @param {*} pageSize
   * @param {*} pageIndex
   */

  onPageSizeChange = (pageSize, pageIndex) => {
    this.setState({ pageSize, pageIndex });
  };

  /**
   *  When user click on column this will sorting the table data
   * @param {*} sorted
   */
  onSortedChange = (sorted) => {
    const { pageSize, pageIndex } = this.state;
    const { id, desc } = sorted[0];
    let obj = {
      page: pageIndex,
      field: id,
      sortOrder: desc ? "DES" : "ASC",
      id: this.props.asset_id,
      productType: "asset",
    };
    id !== "bizLocation.name" &&
      id !== "lastSeen" &&
      this.props.getAssetItemTagList(obj);
  };

  /**
   * Call back for the page changes
   * @param {*} pageIndex
   */

  onPageChange = (pageIndex) => {
    this.setState({ pageIndex });
  };

  handleSelect = async (key, value) => {
    await this.setState({
      pageIndex: 0,
      selectedItem: value,
      selectedKey: key,
    });
    if (["AllItems", "lotNumber"].includes(key)) {
      this.setState({ selectStartDate: "", selectEndDate: "" });
    }
    const { selectStartDate } = this.state;

    key === "AllItems"
      ? this.getAssetItems()
      : key === "lotNumber" || key === "location"
      ? this.getAssetItems({ [key]: value })
      : key === "startDate"
      ? this.setState({ selectStartDate: value })
      : key === "endDate" && selectStartDate !== ""
      ? (this.setState({ selectEndDate: value }),
        this.getAssetItems({
          from: selectStartDate,
          to: value,
        }))
      : this.getAssetItems();
  };

  onEdit = (e, id) => {
    this.setState({ editShow: !this.state.editShow, itemTagId: id });
  };

  handleDelete = () => {
    const { deleteBulkItems } = this.props;
    const { selectedIds, pageSize, pageIndex } = this.state;

    if (selectedIds !== []) {
      deleteBulkItems(selectedIds).then(() => {
        this.getAssetItems();
      });
    }

    this.setState({ selectedIds: [], isMultiSelected: false });
  };

  handleActiveFilter = (val) => {
    const { getItemFilter } = this.props;
    const { selectedIds, isMultiSelected } = this.state;

    if (selectedIds !== []) {
      let filterActive = val === "0" ? false : true;

      getItemFilter(selectedIds, filterActive).then(() => {
        this.getAssetItems();
      });
    }

    this.setState({ selectedIds: [], isMultiSelected: false });
  };

  handleSelectAction = async (value) => {
    // await this.props.changePage(0);
    value === "Delete"
      ? this.handleDelete()
      : value === "0"
      ? this.handleActiveFilter(value)
      : value === "1"
      ? this.handleActiveFilter(value)
      : this.getDrugItems();
  };

  customizeColumns(columns) {
    columns[0].width = 70;
  }
  onRowDblClick(event) {
    if (event.rowType != "group") {
      this.props.history.push(
        "/asset/" + event.data.productGuid + "/" + event.data.GUID
      );
    }
  }

  onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    const { pageIndex, pageSize, isMultiSelected, selectedIds } = this.state;
    let ids = selectedRowKeys;
    let _isMultiSelec = selectedRowKeys.length > 0 ? true : false;
    this.setState({ isMultiSelected: !_isMultiSelec, selectedIds: ids });
  };
  onCellPrepared = (e) => {
    if (e.rowType === "data" && e.column.dataField === "expirationDate") {
      let colorclass =
        e.data.isActive &&
        e.data.expirationDate &&
        (getDateColor(e.data.expirationDate, 2)
          ? "expiryRed"
          : getDateColor(e.data.expirationDate, 5)
          ? "expiryYellow"
          : "expiryBlack");
      let expiryDate = getTime(e.data.expirationDate);
      e.cellElement.className = colorclass;
      e.cellElement.innerText = expiryDate == "Invalid date" ? "" : expiryDate;
    }
    if (e.rowType === "data" && e.column.dataField === "manufactureDate") {
      if (e.data.manufactureDate != null) {
        e.cellElement.innerText = getTime(e.data.manufactureDate);
      }
    }
    if (e.rowType === "data" && e.column.dataField === "purchaseDate") {
      if (e.data.purchaseDate != null) {
        e.cellElement.innerText = getTime(e.data.purchaseDate);
      }
    }
    if (e.rowType === "data" && e.column.dataField === "lastSeen") {
      if (e.data.lastSeen != null) {
        e.cellElement.innerText = getTime(e.data.lastSeen);
      }
    }
  };

  onExporting(e) {
    var PromiseArray = [];
    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
        topLeftCell: { row: 2, column: 2 },
      }).then(() => {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "assetsItems.xlsx"
          );
        });
      });
      e.cancel = true;
    } else if (e.format === "pdf") {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
      }).then(() => {
        doc.save("assetsItems.pdf");
      });
    }
  }

  render() {
    const {
      asset_id,
      assetItemTags,
      lotNumber,
      updateAssetItemTag,
      addAssetItemTag,
      getAssetItemTagList,
      getAssetItemTag,
      itemTotal,
    } = this.props;

    const {
      searchKeyword,
      selectedKey,
      show,
      pageSize,
      pageIndex,
      itemsArray,
      dropdownOpen,
      selectStartDate,
      selectedItem,
      selectEndDate,
      selectedIds,
      isMultiSelected,
      bulkActionOpen,
    } = this.state;

    const columns = [
      {
        width: 40,
        accessor: "check",
        Header: (
          <input
            type="checkbox"
            name="multi-select-box"
            disabled={assetItemTags?.length === 0}
            checked={
              isMultiSelected ||
              (assetItemTags?.length > 0 &&
                selectedIds.length === assetItemTags.length)
            }
            onChange={this.handleMultiSelect}
          />
        ),
        sortable: false,
        filterable: false,
      },

      {
        width: 100,
        Header: "Asset Id",
        accessor: "assetId",
      },
      {
        width: 120,
        Header: "Serial Number",
        accessor: "serialNumber",
      },
      {
        width: 120,
        Header: "Lot Number",
        accessor: "lotNumber",
      },
      {
        width: 160,
        Header: "Manufacture Date",
        accessor: "manufactureDate",
        Cell: ({ row }) => (
          <span>
            {row._original.manufactureDate
              ? getTime(row._original.manufactureDate)
              : ""}
          </span>
        ),
      },
      {
        width: 140,
        Header: "Expiration Date",
        accessor: "expirationDate",
        Cell: ({ row }) => (
          <span
            className={
              row._original.isActive &&
              row._original.expirationDate &&
              (getDateColor(row._original.expirationDate, 2)
                ? "expiryRed"
                : getDateColor(row._original.expirationDate, 5)
                ? "expiryYellow"
                : "expiryBlack")
            }
          >
            {row._original.expirationDate &&
              getTime(row._original.expirationDate)}
          </span>
        ),
      },
      {
        width: 130,
        Header: "Purchase Date",
        accessor: "purchaseDate",
        Cell: ({ row }) => (
          <span>
            {row._original.purchaseDate
              ? getTime(row._original.purchaseDate)
              : ""}
          </span>
        ),
      },
      {
        width: 80,
        Header: "Vendor",
        accessor: "vendor",
      },
      {
        width: 140,
        Header: "Vendor Number",
        accessor: "vendorNumber",
      },
      {
        width: 120,
        Header: "Department",
        accessor: "department",
      },
      // {
      //   width: 200,
      //   Header: "Status",
      //   accessor: "status",
      // },
      {
        width: 100,
        Header: "Location",
        accessor: "bizLocation.name",
      },
      {
        width: 200,
        Header: "Last Seen",
        accessor: "lastSeen",
        Cell: ({ row }) => (
          <span>
            {row._original.lastSeen ? getDateTime(row._original.lastSeen) : ""}
          </span>
        ),
      },
      {
        width: 220,
        Header: "Item EPC",
        accessor: "itemEPC",
      },
    ];

    return (
      <>
        {/* <div className="drug-detail-tag-title">
          {selectedIds.length === 0 ? (
            ""
          ) : (
            <ItemBulkAction
              bulkActionOpen={bulkActionOpen}
              toggleBulk={this.toggleBulk}
              handleSelectAction={this.handleSelectAction}
            />
          )} */}

        {/* <Button
            className="addItemBtn"
            variant="primary"
            onClick={(e) => this.setState({ show: true })}
          >
            Add Asset Item
          </Button> */}
        {/* </div> */}
        {show && (
          <AddAssetItemTag
            asset_id={asset_id}
            show={this.state.show}
            toggleModal={this.toggleModal}
            uniqueLotNumber={lotNumber}
            getAssetItems={this.getAssetItems}
            addAssetItemTag={addAssetItemTag}
            getAssetItemTagList={getAssetItemTagList}
            pageSize={pageSize}
            pageIndex={pageIndex}
          />
        )}

        <Container fluid className="table">
          <Row>
            <Col md="">
              <DataGrid
                id="girdAssetItemList"
                dataSource={this.getPosts()}
                keyExpr="GUID"
                showBorders={false}
                rowAlternationEnabled={false}
                hoverStateEnabled={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode={"widget"}
                width={"100%"}
                onSelectionChanged={this.onSelectionChanged}
                selectedRowKeys={selectedIds}
                ref={this.listItemDataGrid}
                onCellPrepared={this.onCellPrepared}
                onRowClick={(e) => {
                  this.onRowDblClick(e);
                }}
                onRowDblClick={this.onRowDblClick}
                onExporting={this.onExporting}
              >
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey="asset-item-list-grid"
                />
                <FilterPanel visible={true} texts={filterPanelTexts} />
                <FilterBuilderPopup position={filterBuilderPopupPosition} />
                {/* <GroupPanel visible={true} /> */}
                {/* <Grouping autoExpandAll={true} contextMenuEnabled={true} /> */}
                <LoadPanel enabled />
                <FilterRow visible={false} applyFilter={"Immediately"} />
                <Selection mode="multiple" />
                <HeaderFilter visible={true} allowSearch={true} />
                <ColumnChooser enabled={true} mode={"select"} />
                <SearchPanel enabled={true} visible={true} width={300}/>
                <Column dataField="product.name" caption="Name" width={140} />
                <Column dataField="assetId" caption="Asset Id" width={100} />
                <Column
                  dataField="serialNumber"
                  caption="Serial Number"
                  width={120}
                />
                <Column
                  dataField="lotNumber"
                  caption="Lot Number"
                  width={120}
                />
                <Column
                  dataField="manufactureDate"
                  dataType="date"
                  caption="Manufacture Date"
                  width={160}
                />
                <Column
                  dataField="expirationDate"
                  dataType="date"
                  caption="Expiration Date"
                  width={140}
                />
                <Column
                  dataField="purchaseDate"
                  dataType="date"
                  caption="Purchase Date"
                  width={130}
                />
                <Column dataField="vendor" caption="Vendor" width={80} />
                <Column
                  dataField="vendorNumber"
                  caption="Vendor Number"
                  width={140}
                />
                <Column
                  dataField="department"
                  caption="Department"
                  width={120}
                />
                <Column
                  dataField="bizLocation.name"
                  caption="Location"
                  width={100}
                />
                <Column
                  dataField="lastSeen"
                  dataType="date"
                  caption="Last Seen"
                  width={200}
                />
                <Column dataField="itemEPC" caption="Item EPC" width={220} />
                <Summary>
                  <GroupItem
                    column="itemEPC"
                    summaryType="count"
                    displayFormat="{0}"
                  />
                </Summary>
                <Export
                  enabled={true}
                  allowExportSelectedData={true}
                  formats={exportFormats}
                  excelWrapTextEnabled={true}
                />
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={this.state.pageSize} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode={this.state.displayMode}
                  showPageSizeSelector={this.state.showPageSizeSelector}
                  showInfo={this.state.showInfo}
                  showNavigationButtons={this.state.showNavButtons}
                />
                <Toolbar>
                  <Item location="before">
                    <div className="drug-detail-tag-title">
                      {selectedIds.length === 0 ? (
                        ""
                      ) : (
                        <ItemBulkAction
                          bulkActionOpen={bulkActionOpen}
                          toggleBulk={this.toggleBulk}
                          handleSelectAction={this.handleSelectAction}
                        />
                      )}
                    </div>
                  </Item>
                  <Item location="before" locateInMenu={"always"}>
                    <DxButton
                      text="Reset Grid"
                      hint="Reset Grid"
                      icon="revert"
                      onClick={() => {
                        this.listItemDataGrid.current.instance.state(null);
                        this.getAssetItems();
                      }}
                    />
                  </Item>
                  {/* <Item name="groupPanel" location="before" /> */}
                  <Item location="after">
                    <Button
                      className="addItemBtn"
                      variant="primary"
                      onClick={(e) => this.setState({ show: true })}
                    >
                      Add Asset Item
                    </Button>
                  </Item>
                  <Item name="exportButton" location="after" />
                  <Item location="after">
                    <DxButton
                      hint="Refresh"
                      icon="refresh"
                      onClick={() => {
                        this.getAssetItems();
                      }}
                    />
                  </Item>
                  <Item name="columnChooserButton" location="after" />
                  <Item name="searchPanel" location="before" />
                </Toolbar>
              </DataGrid>

              {/* <ReactTable
                data={this.getPosts() || []}
                columns={columns}
                onSortedChange={this.onSortedChange}
                defaultPageSize={this.state.pageSize}
                loadingText="Loading the assets"
                noDataText="Couldn't find assets"
                onPageSizeChange={this.onPageSizeChange}
                onPageChange={this.onPageChange}
                getTdProps={(state, rowInfo, column, instance) => ({
                  onClick: () => {
                    if (rowInfo && column.id !== 'check' && column.id !== 'action') {
                      this.props.history.push({
                        pathname: `/asset/${this.props.match.params.assetId}/${rowInfo.original.GUID}`,
                        state: {
                          assetName: this.props.assetName
                        }
                      })
                    }
                  }
                })}
                getTrGroupProps={(state, rowInfo, column, instance) => {
                  if (rowInfo !== undefined) {
                    for (let i = 0; i < state.data.length; i++) {
                      if (
                        itemsArray.length === 0 &&
                        new Date(state.data[i].expirationDate) < new Date()
                      ) {
                        itemsArray.push(state.data[i].GUID);
                      } else if (
                        new Date(state.data[i].expirationDate) < new Date() &&
                        state.data[i].GUID === rowInfo.original.GUID
                      ) {
                        itemsArray.push(state.data[i].GUID);
                      }
                    }
                    return {
                      style: {
                        color: rowInfo.original.isActive
                          ? itemsArray.includes(rowInfo.original.GUID) && "red"
                          : "gray",
                        background: rowInfo.index % 2 ? '#fffff' : '#F5F5F5'
                      },
                      className: "table-row"
                    };
                  }
                }}
              />
              <Pagination total={itemTotal} currentPage={pageIndex + 1} pageSize={pageSize} /> */}
            </Col>
          </Row>
          {this.state.editShow && (
            <EditAssetItemTag
              asset_id={asset_id}
              itemTagId={this.state.itemTagId}
              editShow={this.state.editShow}
              toggleEditModal={this.toggleEditModal}
              updateAssetItemTag={updateAssetItemTag}
              getAssetItemTagList={getAssetItemTagList}
              getAssetItemTag={getAssetItemTag}
              pageSize={pageSize}
              pageIndex={pageIndex}
            />
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { searchKeyword } = state.searchReducer;
  const { itemTotal } = state.assetItemTagListReducer;
  return {
    searchKeyword,
    itemTotal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAssetItemTag: (assetItemTagObj) =>
      dispatch(AssetsService.addAssetItemTag(assetItemTagObj)),
    getAssetItemTagList: (obj) =>
      dispatch(ProductServices.getAssetsProductItems(obj)),
    deleteBulkItems: (id) =>
      dispatch(ProductServices.deleteBulkProductItems(id)),
    getItemFilter: (ids, isActive) =>
      dispatch(ProductServices.updateProductItemStatus(ids, isActive)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssetItemList)
);
