import DrugDetail from "./views/drugs/DrugsDetail";
import DisposableDetail from "./views/disposable/DisposableDetail";
import Drugs from "./views/drugs/Drugs";
import AddDrug from "./views/drugs/AddDrug";
import Disposable from "./views/disposable/Disposable";
import AddDisposable from "./views/disposable/AddDisposable";
import EditDrug from "./views/drugs/EditDrug";
import EditDisposable from "views/disposable/EditDisposable";
import Assets from "views/assets/Assets";
import AddAsset from "views/assets/AddAsset";
import AssetDetails from "container/Assets/AssetDetails";
import NotFoundPage from "commonComponents/NotFoundPage";
import Users from "views/Users/Users";
import UserOverview from "views/Users/UserOverview";
import AddUser from "views/Users/AddUser";
import Vehicles from "views/vehicles/Vehicles";
import VehicleDetails from "views/vehicles/VehicleDetails";
import AddVehicle from "views/vehicles/AddVehicle";
import EditVehicle from "views/vehicles/EditVehicle";
import EquipmentChecklists from "views/equipmentChecklists/EquipmentChecklists";
import AddEquipmentChecklist from "views/equipmentChecklists/AddEquipmentChecklist";
import ChecklistDetails from "views/equipmentChecklists/EquipmentChecklistDetails";
import ChecklistHistoryDetails from "views/equipmentChecklists/EquipmentChecklistHistoryDetails";
import Packings from "views/Packing/packings";
import PackingDetail from "views/Packing/PackingsDetail";
import Receiving from "views/Receiving/Receiving";
import ReceivingDetail from "views/Receiving/ReceivingDetail";
//import Login from "views/login/Login";

/**
 * Define routes to navigate between the pages
 */
export const dashboardRoutes = [
  {
    path: "/",
    name: "Dashboard",
    icon: "fa-solid fa-square-poll-vertical",
    component: "",
    module: "dashboard",
    layout: "",
    disabled: true,
  },
  // {
  //   path: "/",
  //   name: "",
  //   icon: "",
  //   component:"",
  //   module: '',
  //   layout: "",
  //   disabled: true
  // },
  {
    path: "/vehicles",
    name: "Locations",
    icon: "fas fa-inventory",
    // name: "Vehicles",
    // // icon: "fa-solid fa-truck-medical",
    // icon: "fa-solid fa-truck",
    component: Vehicles,
    module: "vehicle",
    layout: "",
  },
  {
    path: "/addVehicle",
    name: "Vehicle details",
    icon: "nc-icon nc-circle-09",
    module: "vehicle",
    component: AddVehicle,
    layout: "/",
  },
  {
    path: "/vehicle/:vehicleId",
    name: "Vehicle Detais",
    icon: "nc-icon nc-circle-09",
    module: "vehicle",
    component: VehicleDetails,
    layout: "/",
  },
  {
    path: "/editVehicle/:vehicleId",
    name: "Edit Vehicle",
    icon: "fas fa-table",
    module: "vehicle",
    component: AddVehicle,
    layout: "/",
  },

  {
    path: "/inventory",
    name: "Inventory",
    icon: "fa-solid fa-boxes-stacked",
    component: "",
    module: "inventory",
    layout: "",
    disabled: true,
    children: [
      {
        path: "/drugs",
        name: "Drugs",
        icon: "fa-solid fa-capsules",
        component: "",
        module: "drug",
        layout: "",
      },

      {
        path: "/assets",
        name: "Assets",
        // icon: "fa-solid fa-bed-pulse",
        icon: "fas fa-stretcher",
        module: "asset",
        component: "",
        layout: "",
      },
      {
        path: "/disposables",
        name: "Disposables",
        icon: "fa-solid fa-syringe",
        module: "disposable",
        component: "",
        layout: "",
      },
    ],
  },
  {
    path: "/drugs",
    name: "Drugs",
    icon: "fa-solid fa-capsules",
    component: Drugs,
    module: "drug",
    layout: "",
  },
  {
    path: "/drug/:drug_id",
    name: "Drug details",
    icon: "nc-icon nc-circle-09",
    component: DrugDetail,
    module: "drug",
    layout: "/",
  },
  {
    path: "/drug/:drug_id/:drug_item_id",
    name: "Drug details",
    icon: "nc-icon nc-circle-09",
    component: DrugDetail,
    module: "drug",
    layout: "/",
  },
  {
    path: "/addDrug",
    name: "Drug details",
    icon: "nc-icon nc-circle-09",
    module: "drug",
    component: AddDrug,
    layout: "/",
  },
  {
    path: "/assets",
    name: "Assets",
    // icon: "fa-solid fa-bed-pulse",
    icon: "fas fa-stretcher",
    module: "asset",
    component: Assets,
    layout: "",
  },
  {
    path: "/addAsset",
    name: "Add Asset",
    icon: "nc-icon nc-circle-09",
    module: "asset",
    component: AddAsset,
    layout: "/",
  },
  {
    path: "/asset/:assetId",
    name: "Asset Detais",
    icon: "nc-icon nc-circle-09",
    module: "asset",
    component: AssetDetails,
    layout: "/",
  },
  {
    path: "/asset/:assetId/:asset_item_id",
    name: "Asset Detais",
    icon: "nc-icon nc-circle-09",
    module: "asset",
    component: AssetDetails,
    layout: "/",
  },
  {
    path: "/editAsset/:assetId",
    name: "Asset details",
    icon: "fas fa-table",
    module: "asset",
    component: AddAsset,
    layout: "/",
  },
  {
    path: "/editDrug/:drug_id",
    name: "Drug details",
    icon: "fas fa-table",
    module: "drug",
    component: EditDrug,
    layout: "/",
  },
  {
    path: "/disposables",
    name: "Disposables",
    icon: "fa-solid fa-syringe",
    module: "disposable",
    component: Disposable,
    layout: "",
  },
  {
    path: "/addDisposable",
    name: "Disposable details",
    icon: "fas fa-table",
    module: "disposable",
    component: AddDisposable,
    layout: "/",
  },
  {
    path: "/disposable/:disposable_id",
    name: "Disposable details",
    icon: "fas fa-table",
    module: "disposable",
    component: DisposableDetail,
    layout: "/",
  },
  {
    path: "/disposable/:disposable_id/:disposable_item_id",
    name: "Disposable details",
    icon: "fas fa-table",
    module: "disposable",
    component: DisposableDetail,
    layout: "/",
  },
  {
    path: "/editDisposable/:disposable_id",
    name: "Disposable details",
    icon: "fas fa-table",
    module: "disposable",
    component: EditDisposable,
    layout: "/",
  },

  {
    path: "/",
    name: "",
    icon: "",
    component: "",
    module: "",
    layout: "",
    disabled: true,
  },

  // {
  //   path: "/drugs",
  //   name: "Reports",
  //   icon: "fa-solid fa-square-poll-vertical",
  //   component: "",
  //   module: 'reports',
  //   layout: "",
  //   disabled: true
  // },
  {
    path: "/drugs",
    name: "Locations & Devices",
    icon: "fa-solid fa-hospital",
    component: "",
    module: "station",
    layout: "",
    disabled: true,
  },
  {
    path: "/users",
    name: "Users & Roles",
    icon: "fa fa-users",
    module: "user",
    component: Users,
    layout: "",
  },
  {
    path: "/user/:userId",
    name: "User Overview",
    icon: "fas fa-table",
    module: "user",
    component: UserOverview,
    layout: "/",
  },
  {
    path: "/addUser",
    name: "User Overview",
    icon: "fas fa-table",
    module: "user",
    component: AddUser,
    layout: "/",
  },
  {
    path: "/editUser/:userId",
    name: "Edit User",
    icon: "fas fa-table",
    module: "user",
    component: AddUser,
    layout: "/",
  },
  {
    path: "/equipmentChecklists",
    name: "Equipment Checklists",
    icon: "fa-solid fa-clipboard-list",
    component: EquipmentChecklists,
    module: "equipmentChecklist",
    layout: "",
  },
  {
    path: "/addEquipmentChecklist",
    name: "Add Equipment Checklists",
    icon: "fas fa-list",
    module: "equipmentChecklist",
    component: AddEquipmentChecklist,
    layout: "/",
  },
  {
    path: "/equipmentChecklist/:checklistId",
    name: "checklist Detais",
    icon: "nc-icon nc-circle-09",
    module: "equipmentChecklist",
    component: ChecklistDetails,
    layout: "/",
  },
  {
    path: "/equipmentChecklistHistory/:historyId",
    name: "checklist history Detais",
    icon: "nc-icon nc-circle-09",
    module: "equipmentChecklist",
    component: ChecklistHistoryDetails,
    layout: "/",
  },
  {
    path: "/editEquipmentChecklist/:checklistId",
    name: "Edit Checklist",
    icon: "fas fa-table",
    module: "equipmentChecklist",
    component: AddEquipmentChecklist,
    layout: "/",
  },
  {
    path: "/not-found",
    name: "Not Found",
    icon: "fas fa-table",
    component: NotFoundPage,
    layout: "/",
  },

  {
    path: "/",
    name: "",
    icon: "",
    component: "",
    module: "",
    layout: "",
    disabled: true,
  },
  {
    path: "/packing",
    name: "Carton Packing",
    icon: "fa-solid fa-box-open",
    component: Packings,
    module: "Packing",
    layout: "",
  },
  {
    path: "/packing/:packing_id",
    name: "packing details",
    icon: "nc-icon nc-circle-09",
    component: PackingDetail,
    module: "packing",
    layout: "/",
  },
  {
    path: "/",
    name: "",
    icon: "",
    component: "",
    module: "",
    layout: "",
    disabled: true,
  },
  {
    path: "/drugs",
    name: "Sales Orders",
    icon: "far fa-handshake",
    component: "",
    module: "order",
    layout: "",
    disabled: true,
  },
  {
    path: "/drugs",
    name: "Shipments",
    icon: "fa-solid fa-building-circle-arrow-right",
    component: "",
    module: "order",
    layout: "",
    disabled: true,
  },
  {
    path: "/",
    name: "",
    icon: "",
    component: "",
    module: "",
    layout: "",
    disabled: true,
  },
  {
    path: "/drugs",
    name: "Purchase Orders",
    icon: "far fa-shopping-bag",
    component: "",
    module: "order",
    layout: "",
    disabled: true,
  },
  {
    path: "/receiving",
    name: "receiving",
    icon: "fa-solid fa-building-circle-check",
    component: Receiving,
    module: "receiving",
    layout: "",
    // disabled: true,
  },
  {
    path: "/receiving/:receiving_id",
    name: "receiving details",
    icon: "nc-icon nc-circle-09",
    component: ReceivingDetail,
    module: "receiving",
    layout: "/",
  },
  {
    path: "/",
    name: "",
    icon: "",
    component: "",
    module: "",
    layout: "",
    disabled: true,
  },
  {
    path: "/drugs",
    name: "Search",
    icon: "fa-solid fa-magnifying-glass",
    component: "",
    module: "search",
    layout: "",
    disabled: true,
  },
  {
    path: "/drugs",
    name: "Tag Fleet Management",
    icon: "fas fa-table",
    component: "",
    module: "fleet",
    layout: "",
    disabled: true,
  },
];

export const sidebarRoutes = [
  {
    path: "/",
    name: "Dashboard",
    icon: "fa-solid fa-square-poll-vertical",
    component: "",
    module: "dashboard",
    layout: "",
    disabled: true,
  },
  // {
  //   path: "/",
  //   name: "",
  //   icon: "",
  //   component:"",
  //   module: '',
  //   layout: "",
  //   disabled: true
  // },

  {
    path: "/addVehicle",
    name: "Vehicle details",
    icon: "nc-icon nc-circle-09",
    module: "vehicle",
    component: AddVehicle,
    layout: "/",
  },
  {
    path: "/vehicle/:vehicleId",
    name: "Vehicle Detais",
    icon: "nc-icon nc-circle-09",
    module: "vehicle",
    component: VehicleDetails,
    layout: "/",
  },
  {
    path: "/editVehicle/:vehicleId",
    name: "Edit Vehicle",
    icon: "fas fa-table",
    module: "vehicle",
    component: AddVehicle,
    layout: "/",
  },

  {
    path: "/inventory",
    name: "Inventory",
    icon: "fa-solid fa-boxes-stacked",
    component: "",
    module: "inventory",
    layout: "",
    disabled: true,
    children: [
      {
        path: "/drugs",
        name: "Drugs",
        icon: "fa-solid fa-capsules",
        component: "",
        module: "drug",
        layout: "",
      },
      {
        path: "/assets",
        name: "Assets",
        // icon: "fa-solid fa-bed-pulse",
        icon: "fas fa-stretcher",
        module: "asset",
        component: "",
        layout: "",
      },
      {
        path: "/disposables",
        name: "Disposables",
        icon: "fa-solid fa-syringe",
        module: "disposable",
        component: "",
        layout: "",
      },
    ],
  },
  // {
  //   path: "/drugs",
  //   name: "Drugs",
  //   icon: "fa-solid fa-capsules",
  //   component: Drugs,
  //   module: "drug",
  //   layout: "",
  // },
  {
    path: "/drug/:drug_id",
    name: "Drug details",
    icon: "nc-icon nc-circle-09",
    component: DrugDetail,
    module: "drug",
    layout: "/",
  },
  {
    path: "/drug/:drug_id/:drug_item_id",
    name: "Drug details",
    icon: "nc-icon nc-circle-09",
    component: DrugDetail,
    module: "drug",
    layout: "/",
  },
  {
    path: "/addDrug",
    name: "Drug details",
    icon: "nc-icon nc-circle-09",
    module: "drug",
    component: AddDrug,
    layout: "/",
  },
  // {
  //   path: "/assets",
  //   name: "Assets",
  //   // icon: "fa-solid fa-bed-pulse",
  //   icon: "fas fa-stretcher",
  //   module: "asset",
  //   component: Assets,
  //   layout: "",
  // },
  {
    path: "/addAsset",
    name: "Add Asset",
    icon: "nc-icon nc-circle-09",
    module: "asset",
    component: AddAsset,
    layout: "/",
  },
  {
    path: "/asset/:assetId",
    name: "Asset Detais",
    icon: "nc-icon nc-circle-09",
    module: "asset",
    component: AssetDetails,
    layout: "/",
  },
  {
    path: "/asset/:assetId/:asset_item_id",
    name: "Asset Detais",
    icon: "nc-icon nc-circle-09",
    module: "asset",
    component: AssetDetails,
    layout: "/",
  },
  {
    path: "/editAsset/:assetId",
    name: "Asset details",
    icon: "fas fa-table",
    module: "asset",
    component: AddAsset,
    layout: "/",
  },
  {
    path: "/editDrug/:drug_id",
    name: "Drug details",
    icon: "fas fa-table",
    module: "drug",
    component: EditDrug,
    layout: "/",
  },
  // {
  //   path: "/disposables",
  //   name: "Disposables",
  //   icon: "fa-solid fa-syringe",
  //   module: "disposable",
  //   component: Disposable,
  //   layout: "",
  // },
  {
    path: "/addDisposable",
    name: "Disposable details",
    icon: "fas fa-table",
    module: "disposable",
    component: AddDisposable,
    layout: "/",
  },
  {
    path: "/disposable/:disposable_id",
    name: "Disposable details",
    icon: "fas fa-table",
    module: "disposable",
    component: DisposableDetail,
    layout: "/",
  },
  {
    path: "/disposable/:disposable_id/:disposable_item_id",
    name: "Disposable details",
    icon: "fas fa-table",
    module: "disposable",
    component: DisposableDetail,
    layout: "/",
  },
  {
    path: "/editDisposable/:disposable_id",
    name: "Disposable details",
    icon: "fas fa-table",
    module: "disposable",
    component: EditDisposable,
    layout: "/",
  },

  // {
  //   path: "/",
  //   name: "",
  //   icon: "",
  //   component: "",
  //   module: "",
  //   layout: "",
  //   disabled: true,
  // },

  // {
  //   path: "/drugs",
  //   name: "Reports",
  //   icon: "fa-solid fa-square-poll-vertical",
  //   component: "",
  //   module: 'reports',
  //   layout: "",
  //   disabled: true
  // },
  {
    path: "/locations",
    name: "Locations & Devices",
    icon: "fa-solid fa-hospital",
    component: "",
    module: "station",
    layout: "",
    disabled: true,
    children: [
      {
        path: "/equipmentChecklists",
        name: "Equipment Checklists",
        icon: "fa-solid fa-clipboard-list",
        component: EquipmentChecklists,
        module: "equipmentChecklist",
        layout: "",
      },
      {
        path: "/vehicles",
        name: "Locations",
        icon: "fas fa-inventory",
        // name: "Vehicles",
        // // icon: "fa-solid fa-truck-medical",
        // icon: "fa-solid fa-truck",
        component: Vehicles,
        module: "vehicle",
        layout: "",
      },
      {
        path: "/users",
        name: "Users & Roles",
        icon: "fa fa-users",
        module: "user",
        component: Users,
        layout: "",
      },
    ],
  },
  {
    path: "/drugs",
    name: "Sample Management",
    icon: "fas fa-flask-vial",
    component: "",
    module: "order",
    layout: "",
    disabled: true,
    children: [
      {
        path: "/TBD",
        name: "Samples",
        // icon: "fa-solid fa-bed-pulse",
        icon: "fas fa-vials",
        module: "TBD",
        component: "",
        layout: "",
        disabled: true,
      },
      {
        path: "/TBD",
        name: "Lab Tests",
        // icon: "fa-solid fa-bed-pulse",
        icon: "fas fa-vial-circle-check",
        module: "TBD",
        component: "",
        layout: "",
        disabled: true,
      },
    ],
  },
  // {
  //   path: "/user/:userId",
  //   name: "User Overview",
  //   icon: "fas fa-table",
  //   module: "user",
  //   component: UserOverview,
  //   layout: "/",
  // },
  // {
  //   path: "/addUser",
  //   name: "User Overview",
  //   icon: "fas fa-table",
  //   module: "user",
  //   component: AddUser,
  //   layout: "/",
  // },
  // {
  //   path: "/editUser/:userId",
  //   name: "Edit User",
  //   icon: "fas fa-table",
  //   module: "user",
  //   component: AddUser,
  //   layout: "/",
  // },

  {
    path: "/addEquipmentChecklist",
    name: "Add Equipment Checklists",
    icon: "fas fa-list",
    module: "equipmentChecklist",
    component: AddEquipmentChecklist,
    layout: "/",
  },
  {
    path: "/equipmentChecklist/:checklistId",
    name: "checklist Detais",
    icon: "nc-icon nc-circle-09",
    module: "equipmentChecklist",
    component: ChecklistDetails,
    layout: "/",
  },
  {
    path: "/equipmentChecklistHistory/:historyId",
    name: "checklist history Detais",
    icon: "nc-icon nc-circle-09",
    module: "equipmentChecklist",
    component: ChecklistHistoryDetails,
    layout: "/",
  },
  {
    path: "/editEquipmentChecklist/:checklistId",
    name: "Edit Checklist",
    icon: "fas fa-table",
    module: "equipmentChecklist",
    component: AddEquipmentChecklist,
    layout: "/",
  },
  {
    path: "/not-found",
    name: "Not Found",
    icon: "fas fa-table",
    component: NotFoundPage,
    layout: "/",
  },

  // {
  //   path: "/",
  //   name: "",
  //   icon: "",
  //   component: "",
  //   module: "",
  //   layout: "",
  //   disabled: true,
  // },
  {
    path: "/packing",
    name: "Carton Packing",
    icon: "fa-solid fa-box-open",
    component: Packings,
    module: "Packing",
    layout: "",
  },
  {
    path: "/packing/:packing_id",
    name: "packing details",
    icon: "nc-icon nc-circle-09",
    component: PackingDetail,
    module: "packing",
    layout: "/",
  },
  // {
  //   path: "/",
  //   name: "",
  //   icon: "",
  //   component: "",
  //   module: "",
  //   layout: "",
  //   disabled: true,
  // },
  {
    path: "/drugs",
    name: "Sales Orders",
    icon: "far fa-handshake",
    component: "",
    module: "order",
    layout: "",
    disabled: true,
  },
  {
    path: "/drugs",
    name: "Shipments",
    icon: "fa-solid fa-building-circle-arrow-right",
    component: "",
    module: "order",
    layout: "",
    disabled: true,
  },
  // {
  //   path: "/",
  //   name: "",
  //   icon: "",
  //   component: "",
  //   module: "",
  //   layout: "",
  //   disabled: true,
  // },
  {
    path: "/drugs",
    name: "Purchase Orders",
    icon: "far fa-shopping-bag",
    component: "",
    module: "order",
    layout: "",
    disabled: true,
  },
  {
    path: "/receiving",
    name: "Receiving",
    icon: "fa-solid fa-building-circle-check",
    component: Receiving,
    module: "receiving",
    layout: "",
    // disabled: true,
  },
  {
    path: "/receiving/:receiving_id",
    name: "receiving details",
    icon: "nc-icon nc-circle-09",
    component: ReceivingDetail,
    module: "receiving",
    layout: "/",
  },
  // {
  //   path: "/",
  //   name: "",
  //   icon: "",
  //   component: "",
  //   module: "",
  //   layout: "",
  //   disabled: true,
  // },
  {
    path: "/drugs",
    name: "Search",
    icon: "fa-solid fa-magnifying-glass",
    component: "",
    module: "search",
    layout: "",
    disabled: true,
  },
  {
    path: "/drugs",
    name: "Tag Fleet Management",
    icon: "fas fa-table",
    component: "",
    module: "fleet",
    layout: "",
    disabled: true,
  },
];
