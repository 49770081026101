import React, { Component } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import ModalDialog from "../../commonComponents/ModalDialog";
import { BulkAction } from "../../commonComponents/ItemDropDown";
import AssetsService from "service/AssetsService";
import { AssetColumns } from "Constant/Column";
import ProductServices from "service/CommonServices";
import { Col, Container } from "react-bootstrap";
import { getTime, getDateColor } from "commonMethod/common";
import Placeholder from "../../assets/img/placeholder-picture-image.png";
import { Button as DxButton } from "devextreme-react/button";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { jsPDF } from "jspdf";
import DataGrid, {
  Scrolling,
  ColumnChooser,
  Selection,
  Pager,
  Column,
  Paging,
  Toolbar,
  StateStoring,
  Button as GridButton,
  Item,
  HeaderFilter,
  SearchPanel,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterBuilderPopup,
  Summary,
  GroupItem,
  TotalItem,
} from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react/load-panel";
import { BaseURL } from "../../Constant/BaseURL";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { exportDataGrid } from "devextreme/excel_exporter";
import { createStore } from "devextreme-aspnet-data-nojquery";

const allowedPageSizes = [5, 10, 20, 25, 50, "all"];
const position = { of: "#gridAssetContainer" };

const renderGridCell = (data) => {
  return data.text == "" ? (
    ""
  ) : getDateColor(data.text, 2) ? (
    <div style={{ color: "red" }}>{getTime(data.text)}</div>
  ) : getDateColor(data.text, 5) ? (
    <div style={{ color: "#E8912D" }}>{getTime(data.text)}</div>
  ) : (
    <div style={{ color: "black" }}>{getTime(data.text)}</div>
  );
};
const dataSource = createStore({
  key: "GUID",
  loadUrl: `${BaseURL}/products?productType=asset`,
});

const exportFormats = ["pdf", "xlsx"];

const filterBuilderPopupPosition = {
  of: window,
  at: "top",
  my: "top",
  offset: { y: 10 },
};
const filterPanelTexts = {
  clearFilter: "CLEAR FILTER"
};
class Assets extends Component {
  constructor(props) {
    super(props);
    this.dataGrid = React.createRef();
    this.state = {
      show: false,
      isMultiSelected: false,
      selectedFilter: "",
      columnModal: false,
      categoryStatus: "Active",
      selectedIds: [],
      categoryName: "",
      selectedItem: "",
      categoryList: [],
      isMatched: false,
      dropdownOpen: false,
      bulkActionOpen: false,
      description: "",
      isDescription: false,
      searchKeyword: "",
      pageIndex: "",
    };
    this.onExporting = this.onExporting.bind(this);
    if (localStorage.getItem("assetsColumns")) {
      localStorage.getItem("assetsColumns");
    } else {
      localStorage.setItem("assetsColumns", JSON.stringify(AssetColumns));
    }
  }

  toggleFilter = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  toggleBulk = () => {
    this.setState({ bulkActionOpen: !this.state.bulkActionOpen });
  };

  componentDidMount() {
    this.sync();
    this.props.getAllCategory();
  }

  getSelectedIds = (ids) => {
    this.setState({ selectedIds: ids });
  };

  sync = async (newObj) => {
    this.getPosts();
  };
  clearFilter = () => {
    this.setState({
      selectedItem: "",
    });
  };

  clearMultiselect = () => {
    this.setState({
      selectedIds: [],
      isMultiSelected: false,
    });
  };

  clearCategory = () => {
    this.setState({
      categoryList: "",
    });
  };

  setNewCategory = (categoryName) => {
    this.setState({ categoryName, isMatched: false });
  };

  handleDelete = () => {
    const { deleteBulkAssets } = this.props;
    const { selectedIds } = this.state;
    if (selectedIds !== []) {
      deleteBulkAssets(selectedIds).then(() => {
        this.sync();
        this.dataGrid.current.instance.refresh();
      });
    }
    this.setState({ selectedIds: [], isMultiSelected: false });
  };

  handleActiveFilter = (val) => {
    const { getProductFilter } = this.props;
    const { selectedIds } = this.state;
    if (selectedIds !== []) {
      let filterActive = val === "0" ? false : true;
      getProductFilter(selectedIds, filterActive).then(() => {
        this.sync();
      });
    }
    this.setState({
      selectedIds: [],
      isMultiSelected: false,
      selectedItem: "",
    });
  };

  handleSelectAction = async (value) => {
    // await this.props.changePage(0);
    value === "Category"
      ? this.setState({ show: !this.state.show })
      : value === "Delete"
      ? this.handleDelete()
      : value === "0"
      ? this.handleActiveFilter(value)
      : value === "1"
      ? this.handleActiveFilter(value)
      : this.sync();
  };

  handleSelect = async (value) => {
    this.setState({
      selectedItem: value,
    });
    // await this.props.changePage(0);
    const {
      getFilteredAssetList,
      filterProductByCategory,
      pageIndex,
      pageSize,
    } = this.props;
    value === "All Assets"
      ? this.sync()
      : value === "0" || value === "1"
      ? this.sync({
          isActive: parseInt(value),
        })
      : this.sync({
          isActive: "1",
          category: value,
        });
    // if(value === '1'){
    // }else if(['All Assets', '0'].includes(value)){
    //   this.setState({categoryStatus: ''})
    // }
  };

  handleColumnSelectorModal = () => {
    this.setState({ columnModal: !this.state.columnModal });
  };

  getMultiSelected = (e) => {
    this.setState({ isMultiSelected: e });
  };
  createAndReturnCheckBox = () => {
    return this.props.assetsList
      ? this.props.assetsList.map((data) => {
          data["check"] = (
            <div style={{ textAlign: "center" }}>
              <input
                type="checkbox"
                name={data.GUID}
                checked={this.state.selectedIds.includes(data.GUID)}
                onClick={(event) => this.handleClickGroup(data.GUID)}
              />
            </div>
          );
          return data;
        })
      : [];
  };
  // onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
  //   const { pageIndex, pageSize, isMultiSelected, selectedIds } = this.state;
  //   let ids = selectedRowKeys;
  //   let _isMultiSelec = selectedRowKeys.length > 0 ? true : false;
  //   this.setState({
  //     isMultiSelected: !_isMultiSelec,
  //     selectedIds: [],
  //   });

  //   this.setState({ selectedIds: selectedRowKeys });
  // };
  onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    const { pageIndex, pageSize, isMultiSelected, selectedIds } = this.state;
    let ids = selectedRowKeys;
    let _isMultiSelec = selectedRowKeys.length > 0 ? true : false;
    this.setState({ isMultiSelected: !_isMultiSelec, selectedIds: ids });
  };

  getPosts = () => {
    let assetColumns = JSON.parse(localStorage.getItem("assetsColumns"));
    let allTrueColumns = {};
    assetColumns.map((item) => {
      allTrueColumns[item.accessor] = item.checked;
    });

    const list = this.createAndReturnCheckBox();

    const filteredList =
      this.state.searchKeyword &&
      this.state.searchKeyword !== "" &&
      list.length > 0
        ? list.filter((post) => {
            return (
              post.name
                .toLowerCase()
                .startsWith(this.state.searchKeyword.toLowerCase()) ||
              (allTrueColumns.manufacturer &&
                post.manufacturer
                  .toLowerCase()
                  .startsWith(this.state.searchKeyword.toLowerCase())) ||
              (allTrueColumns.UNSPSCCode &&
                post.UNSPSCCode.toLowerCase().startsWith(
                  this.state.searchKeyword.toLowerCase()
                )) ||
              (allTrueColumns.productCode &&
                post.productCode
                  .toLowerCase()
                  .startsWith(this.state.searchKeyword.toLowerCase())) ||
              (allTrueColumns.modelNumber &&
                post.modelNumber.startsWith(
                  this.state.searchKeyword.toLowerCase()
                )) ||
              (allTrueColumns.brand &&
                post.brand
                  .toLowerCase()
                  .startsWith(this.state.searchKeyword.toLowerCase())) ||
              (allTrueColumns.description &&
                post.description
                  .toLowerCase()
                  .startsWith(this.state.searchKeyword.toLowerCase())) ||
              post.productCategory.find((item) =>
                item.name
                  .toLowerCase()
                  .startsWith(this.state.searchKeyword.toLowerCase())
              )
            );
          })
        : list;
    this.Assets = filteredList;
    return filteredList;
  };
  getColumns = async (column) => {
    localStorage.setItem("assetsColumns", JSON.stringify(column));
    // await this.props.updateAssetsColumns(column);
    this.setState({
      columnModal: false,
    });
  };

  toggleModal = (e) => {
    this.setState({ show: !this.state.show });
  };

  handleSubmit = async (e) => {
    const { getProductCategory } = this.props;
    const { selectedIds, categoryList } = this.state;
    // let isDescription = description.length === 0;
    let isMatched = categoryList.length === 0;

    if (!isMatched) {
      let category = categoryList[0].name; // categoryList.map((item) => item.name);
      let obj = {
        GUIDS: selectedIds,
        name: category,
        // description : description
      };
      await getProductCategory(obj, "drug").then(() => {
        this.sync();
      });
      this.setState({
        show: !this.state.show,
        categoryList: [],
        selectedIds: [],
      });
    } else {
      this.setState({ isMatched });
    }
  };

  // // setDescription = (text) => {
  // //   this.setState({ "description" : text, isDescription: false });
  // // };

  onSelect = (selectedList, selectedItem) => {
    this.setState({
      categoryName: "",
      categoryList: selectedList,
      isMatched: false,
    });
  };

  onAddCategory = () => {
    let text = this.state.categoryName;
    let newCategory = [...this.state.categoryList];
    let isMatched = newCategory.find((i) => i.name === text);
    if (!isMatched) {
      newCategory.push({ name: text, id: "" });
      this.setState({
        categoryList: [...newCategory],
        categoryName: "",
      });
    } else {
      this.setState({
        isMatched: true,
      });
    }
  };

  onPageSizeChange = async (pageSize, pageIndex) => {
    await this.props.changePageSize(pageSize);
    // this.sync();
  };

  onPageIndexChange = async (pageIndex, e) => {
    await this.props.changeAssetsCommonPageIndex(pageIndex);
    // this.sync();
  };
  onRowClick(e) {
    if (e.rowType != "group") {
      this.props.history.push({
        pathname: `/asset/${e.key}`,
        state: {
          assetName: e.data.name,
        },
      });
    }
  }
  DiffCell(cellData) {
    return (
      <div>
        {cellData.data.image ? (
          <img
            src={cellData.data.image}
            id={cellData.data.GUID}
            height="50"
            width="50"
          ></img>
        ) : (
          <img src={Placeholder} height="50" width="50"></img>
        )}
      </div>
    );
  }
  addImageExcel(url, workbook, worksheet, excelCell, resolve) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        var base64data = reader.result;
        const image = workbook.addImage({
          base64: base64data,
          extension: "png",
        });
        worksheet.getRow(excelCell.row).height = 75;
        worksheet.addImage(image, {
          tl: { col: excelCell.col - 1, row: excelCell.row - 1 },
          br: { col: excelCell.col, row: excelCell.row },
        });
        resolve();
      };
    };
    xhr.onerror = function () {
      console.error("could not add image to excel cell");
    };
    xhr.send();
  }
  addImagePdf(url, resolve, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        var base64data = reader.result;
        callback(base64data);
        resolve();
      };
    };
    xhr.onerror = function () {
      console.error("could not add image to excel cell");
    };
    xhr.send();
  }

  onExporting(e) {
    var PromiseArray = [];
    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
        topLeftCell: { row: 2, column: 2 },
        customizeCell: async ({ gridCell, excelCell }) => {
          if (gridCell.rowType === "data") {
            if (gridCell.column.dataField === "IMAGE") {
              excelCell.value = undefined;
              if (gridCell.data.image != null || gridCell.data.image != "") {
                PromiseArray.push(
                  new Promise((resolve, reject) => {
                    this.addImageExcel(
                      gridCell.data.image,
                      workbook,
                      worksheet,
                      excelCell,
                      resolve
                    );
                  })
                );
              }
            }
          }
        },
      }).then(() => {
        Promise.all(PromiseArray).then(() => {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "assets.xlsx"
            );
          });
        });
      });
      e.cancel = true;
    } else if (e.format === "pdf") {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
        customDrawCell: (e) => {
          if (e.gridCell.rowType === "data") {
            if (e.gridCell.column.dataField === "IMAGE") {
              PromiseArray.push(
                new Promise((resolve, reject) => {
                  if (
                    e.gridCell.data.image != null ||
                    e.gridCell.data.image != ""
                  ) {
                    this.addImagePdf(
                      e.gridCell.data.image,
                      resolve,
                      function (myBase64) {
                        if (
                          myBase64.includes("image/png") ||
                          myBase64.includes("image/jpg") ||
                          myBase64.includes("image/jpeg")
                        ) {
                          console.log(myBase64, e.gridCell.data.image);
                          doc.addImage(
                            myBase64,
                            "png",
                            e.rect.x,
                            e.rect.y,
                            e.rect.w,
                            e.rect.h
                          );
                          e.cancel = true;
                        }
                      }
                    );
                  }
                })
              );
            }
          }
        },
      }).then(() => {
        Promise.all(PromiseArray).then(() => {
          doc.save("assets.pdf");
        });
      });
    }
  }
  render() {
    const {
      categories,
      history,
      total,
      pageIndex,
      pageSize,
      currentPage,
      activeCategories,
      assetCommonPageIndex,
    } = this.props;
    const {
      isMatched,
      columnModal,
      categoryList,
      selectedItem,
      show,
      categoryStatus,
      selectedIds,
      bulkActionOpen,
      dropdownOpen,
      categoryName,
    } = this.state;
    const options =
      categories && categories.length > 0
        ? categories.map((i) => {
            return { name: i.name, id: i.id };
          })
        : [];
    let assetColumns = JSON.parse(localStorage.getItem("assetsColumns"));

    return (
      <>
        <div className="drugs-main-table">
          {/* <AssetsContainerTable
            pageSize={pageSize}
            pageIndex={pageIndex}
            getSelectedIds={this.getSelectedIds}
            getMultiSelected={this.getMultiSelected}
            isMultiSelectedProps={this.state.isMultiSelected}
            selectedIdProps={this.state.selectedIds}
            columns={AssetColumns}
            total={total}
            pages={total / pageSize}
            currentPage={currentPage}
            onPageSizeChange={this.onPageSizeChange}
            onPageChange={this.onPageChange}
          /> */}
          <>
            <Container fluid>
              <Row>
                {show && (
                  <ModalDialog
                    show={show}
                    title={"Add Category"}
                    closeDialog={this.toggleModal}
                    onSubmit={this.handleSubmit}
                  >
                    <Col sm="12">
                      <Form.Group>
                        <Form.Label column sm="2">
                          Categories
                        </Form.Label>
                        <Col sm="6">
                          <Multiselect
                            options={options}
                            selectedValues={
                              categoryList.length > 0 ? categoryList : ""
                            }
                            onSelect={this.onSelect}
                            onRemove={this.onSelect}
                            singleSelect={true}
                            placeholder={
                              categoryList.length > 0 ? "" : "Select Category"
                            }
                            displayValue="name"
                          />
                          {isMatched && (
                            <span column sm={`1`} className="danger-action">
                              {" "}
                              {"Category not selected."}
                            </span>
                          )}
                        </Col>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label column sm={"2"}></Form.Label>
                        <Col sm={"4"}>
                          <Form.Control
                            value={categoryName}
                            type={"input"}
                            isInvalid={isMatched}
                            placeholder={"Add New Category"}
                            onChange={(e) => {
                              this.setNewCategory(e.target.value);
                              this.clearCategory();
                            }}
                          />
                          {isMatched && (
                            <span column sm={`1`} className="danger-action">
                              {categoryName + " is already in categories."}
                            </span>
                          )}
                        </Col>
                        {categoryName !== "" && (
                          <Button
                            variant="primary"
                            onClick={this.onAddCategory}
                          >
                            Add Category
                          </Button>
                        )}
                      </Form.Group>
                      {/* <Form.Group>
                  <Form.Label column sm="2">
                    Description
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      value={description}
                      type={"input"}
                      isInvalid={isDescription}
                      placeholder={"description"}
                      name="description"
                      onChange={(e) => {
                        this.setDescription(e.target.value);
                      }}
                    />
                    {isDescription && (
                      <span column sm={`1`} className="danger-action">
                        {" Description is required"}
                      </span>
                    )}
                  </Col>
                </Form.Group> */}
                    </Col>
                  </ModalDialog>
                )}
                <Col lg="2" md="2" sm="4">
                  {/* <div style={{ display: "flex" }}>
                      {selectedIds.length > 0 ? (
                        <BulkAction
                          bulkActionOpen={this.state.bulkActionOpen}
                          toggleBulk={this.toggleBulk}
                          handleSelectAction={this.handleSelectAction}
                        />
                      ) : (
                        ""
                      )}
                    </div> */}
                </Col>
                <Col lg="10" md="10" sm="8" className="table-inner-actions">
                  {/* <div>
                      <Button variant="primary">
                        <Link to="/addAsset"> + New</Link>
                      </Button>
                    </div> */}
                </Col>
              </Row>
              <Col md="">
                <DataGrid
                  id="gridAssetContainer"
                  dataSource={dataSource}
                  keyExpr="GUID"
                  hoverStateEnabled={true}
                  // rowAlternationEnabled={true}
                  showRowLines={false}
                  selectedRowKeys={selectedIds}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnResizingMode={"widget"}
                  width={"100%"}
                  defaultSelectedRowKeys={selectedIds}
                  customizeColumns={this.customizeColumns}
                  onSelectionChanged={this.onSelectionChanged}
                  onExporting={this.onExporting}
                  ref={this.dataGrid}
                  onRowClick={(e) => {
                    this.onRowClick(e);
                  }}
                  onRowDblClick={(e) => {
                    this.onRowClick(e);
                  }}
                  columnAutoWidth={true}
                >
                  <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey="assets-main-grid"
                    savingTimeout={100}
                  />
                  <FilterPanel visible={true} texts={filterPanelTexts} />
                  <FilterBuilderPopup position={filterBuilderPopupPosition} />
                  {/* <GroupPanel visible={true} /> */}
                  {/* <Grouping autoExpandAll={true} contextMenuEnabled={true} /> */}
                  <LoadPanel enabled position={position} />
                  <FilterRow visible={false} applyFilter={"Immediately"} />
                  <ColumnChooser enabled={true} mode={"select"} />
                  <SearchPanel enabled={true} visible={true} width={300} />
                  <Toolbar>
                    <Item location="before">
                      <div style={{ display: "flex" }}>
                        {selectedIds.length > 0 ? (
                          <BulkAction
                            bulkActionOpen={this.state.bulkActionOpen}
                            toggleBulk={this.toggleBulk}
                            handleSelectAction={this.handleSelectAction}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Item>
                    <Item location="before" locateInMenu={"always"}>
                      <DxButton
                        text="Reset Grid"
                        hint="Reset Grid"
                        icon="revert"
                        onClick={() => {
                          this.dataGrid.current.instance.state(null);
                          this.sync();
                        }}
                      />
                    </Item>
                    {/* <Item name="groupPanel" location="before" /> */}
                    <Item location="after">
                      <Button variant="primary">
                        <Link to="/addAsset"> + New</Link>
                      </Button>
                    </Item>
                    <Item name="exportButton" location="after" />
                    <Item location="after">
                      <DxButton
                        hint="Refresh"
                        icon="refresh"
                        onClick={() => {
                          this.sync();
                          this.dataGrid.current.instance.refresh();
                        }}
                      />
                    </Item>
                    <Item name="columnChooserButton" location="after" />
                    <Item name="searchPanel" location="before" />
                  </Toolbar>

                  <Column
                    dataField="IMAGE"
                    caption="IMAGE"
                    cellRender={this.DiffCell}
                    width="70"
                    allowFiltering={false}
                    allowGrouping={false}
                    allowSorting={false}
                      allowResizing={false}
                  ></Column>

                  <Column
                    dataField="name"
                    caption="NAME"
                    allowHiding={false}
                  ></Column>
                  <Column
                    dataField="manufacturer"
                    caption="MANUFACTURER"
                    visible={true}
                  />
                  <Column
                    dataField="productCode"
                    caption="EAN/UPC/GTIN/SKU"
                    width="200"
                    visible={true}
                  />
                  <Column
                    dataField="UNSPSCCode"
                    caption="UNSPSC CODE"
                    visible={true}
                  />
                  <Column
                    dataField="modelNumber"
                    caption="MODEL/PART NUMBER"
                    width="170"
                    visible={true}
                  />
                  <Column dataField="brand" caption="BRAND" visible={true} />
                  <Column
                    dataField="inventoryQuantity"
                    caption="INVENTORY QUANTITY"
                    width="170"
                    alignment="center"
                    visible={true}
                  />
                  <Column
                    dataField="expiryDate"
                    caption="NEXT EXPIRY"
                    dataType="date"
                    cellRender={renderGridCell}
                    visible={true}
                    customizeText={(cellInfo) => {
                      return getTime(cellInfo.value) == "Invalid date"
                        ? ""
                        : getTime(cellInfo.value);
                    }}
                  />
                    <Column
                      width={125}
                      type="buttons"
                      allowFiltering={false}
                      allowGrouping={false}
                      allowSorting={false}
                    >
                      <GridButton render={renderReorderButton} />
                    </Column>
                  <Export
                    enabled={true}
                    allowExportSelectedData={true}
                    formats={exportFormats}
                    excelWrapTextEnabled={true}
                  />
                  <HeaderFilter visible={true} allowSearch={true} />
                  <Selection mode="multiple" />
                  <Scrolling rowRenderingMode="virtual"></Scrolling>
                  <Paging
                    defaultPageSize={this.props.pageSize}
                    onPageSizeChange={this.onPageSizeChange}
                    // onPageIndexChange={this.onPageIndexChange}
                    // pageIndex={this.state.pageSize}
                  />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"adaptive"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <Summary>
                    <GroupItem
                      column="name"
                      summaryType="count"
                      displayFormat="{0}"
                    />
                    {/* <GroupItem
                        column="inventoryQuantity"
                        summaryType="sum"
                        showInGroupFooter={true}
                        displayFormat={"Sub-total: {0}"}
                      />
                      <GroupItem
                        name="expiryDateGroupSummary"
                        showInColumn="expiryDate"
                        summaryType="custom"
                        showInGroupFooter={true}
                      /> */}
                    <TotalItem
                      column="inventoryQuantity"
                      summaryType="sum"
                      displayFormat={"TOTAL: {0}"}
                    />
                  </Summary>
                </DataGrid>
              </Col>
            </Container>
          </>
        </div>
      </>
    );
  }
}

const calculateCustomSummary = (options) => {
  // REF: https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/summary/
  if (options.name == "expiryDateGroupSummary") {
    // Enforce blank/empty value in group summary for 'EXPIRY DATE' column
    options.totalValue = {};
  }
};

const renderReorderButton = (button) => {
  return <Button variant="primary" size="sm" disabled style={{ fontFamily: "Inter, \"Source Sans Pro\", Helvetica, Arial, sans-serif", fontSize: "11px", verticalAlign: "middle" }}>REORDER</Button>;
};

const mapStateToProps = (state) => {
  const {
    assetsList,
    categories,
    assetColumns,
    pageIndex,
    pageSize,
    total,
    activeCategories,
    currentPage,
    rows,
    assetCommonPageIndex,
  } = state.assetsReducer;
  return {
    assetsList,
    categories,
    assetColumns,
    activeCategories,
    pageSize,
    pageIndex,
    currentPage,
    rows,
    total,
    assetCommonPageIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAssetsList: (obj) =>
      dispatch(ProductServices.getAllAssetsProductList(obj)),
    getActiveCategory: (obj) =>
      dispatch(ProductServices.getActiveCategory(obj)),
    deleteBulkAssets: (id) => dispatch(ProductServices.deleteBulkProducts(id)), //dispatch(AssetsService.deleteBulkAssets(id)),
    getProductFilter: (ids, isActive) =>
      dispatch(ProductServices.updateProductStatus(ids, isActive)), //dispatch(AssetsService.getProductFilter(ids, isActive)),
    getFilteredAssetList: (value) =>
      dispatch(AssetsService.getFilteredAssetsList(value)),
    getProductCategory: (obj, productType) =>
      dispatch(ProductServices.addProductCategory(obj, productType)),
    filterProductByCategory: (category, obj) =>
      dispatch(AssetsService.filterProductByCategory(category, obj)),
    getAllCategory: () => dispatch(ProductServices.getAllCategory()),
    updateAssetsColumns: (columns) =>
      dispatch(AssetsService.updateAssetsColumns(columns)),
    changePage: (pageNo) => dispatch(AssetsService.changeAssetsPage(pageNo)),
    changePageSize: (pageSize) =>
      dispatch(AssetsService.changeAssetsPageSize(pageSize)),
    changeAssetsCommonPageIndex: (gridIndex) =>
      dispatch(AssetsService.changeAssetsCommonPage(gridIndex)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Assets);
