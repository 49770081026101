export let AssetColumns = [
  {
    header: "Image",
    accessor: "image",
    checked: true,
  },
  {
    header: "Name",
    accessor: "name",
    checked: true,
  },
  {
    header: "Manufacturer",
    accessor: "manufacturer",
    checked: true,
  },
  {
    header: "EAN/UPC/GTIN/SKU",
    accessor: "productCode",
    checked: true,
  },
  {
    header: "UNSPSC Code ",
    accessor: "UNSPSCCode",
    checked: true,
  },
  {
    header: "Model/Part Number ",
    accessor: "modelNumber",
    checked: true,
  },
  {
    header: "Brand",
    accessor: "brand",
    checked: true,
  },
  {
    header: "Inventory Quantity",
    accessor: "inventoryQuantity",
    checked: true,
  },
  {
    header: "Next Expiry",
    accessor: "expiryDate",
    checked: true,
  },
  // {
  //   header: "Description",
  //   accessor: "description",
  //   checked: true,
  // },
];

export let DisposableColumns = [
  {
    header: "Image",
    accessor: "image",
    checked: true,
  },
  {
    header: "Name",
    accessor: "name",
    checked: true,
  },
  {
    header: "Manufacturer",
    accessor: "manufacturer",
    checked: true,
  },
  // {
  //   header: "EAN/UPC/GTIN/SKU",
  //   accessor: "productCode",
  //   checked: true,
  // },
  {
    header: "UNSPSC Code",
    accessor: "UNSPSCCode",
    checked: true,
  },
  {
    header: "Model/Part Number ",
    accessor: "modelNumber",
    checked: true,
  },
  {
    header: "Brand",
    accessor: "brand",
    checked: true,
  },
  // {
  //   header: 'Status',
  //   accessor: 'isActive',
  //   checked: true,
  // },
  {
    header: "Net Content",
    accessor: "netContent",
    checked: true,
  },
  {
    header: "Inventory Quantity",
    accessor: "inventoryQuantity",
    checked: true,
  },
  {
    header: "Next Expiry",
    accessor: "expiryDate",
    checked: true,
  },
  // {
  //   header: "Description",
  //   accessor: "description",
  //   checked: true,
  // },
];

export let DrugsColumns = [
  {
    header: "Image",
    accessor: "image",
    checked: true,
  },
  {
    header: "Name",
    accessor: "name",
    checked: true,
  },
  {
    header: "Manufacturer",
    accessor: "manufacturer",
    checked: true,
  },
  // {
  //   header: "EAN/UPC/GTIN/SKU",
  //   accessor: "productCode",
  //   checked: true,
  // },
  {
    header: "UNSPSC Code ",
    accessor: "UNSPSCCode",
    checked: true,
  },
  // {
  //   header: 'Status',
  //   accessor: 'isActive',
  //   checked: true,
  // },

  {
    header: "NDC",
    accessor: "ndc",
    checked: true,
  },
  {
    header: "Dosage",
    accessor: "dossage",
    checked: true,
  },
  {
    header: "Strength",
    accessor: "strength",
    checked: true,
  },
  {
    header: "Net Content",
    accessor: "netContent",
    checked: true,
  },
  {
    header: "Inventory Quantity",
    accessor: "inventoryQuantity",
    checked: true,
  },
  {
    header: "Next Expiry",
    accessor: "expiryDate",
    checked: true,
  },
  // {
  //   header: "Description",
  //   accessor: "description",
  //   checked: true,
  // },
];
export let assetStatusOption = [
  {
    label: "Select Status",
    value: "",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Decommissioned",
    value: "Decommissioned",
  },
  {
    label: "Needs Servicing",
    value: "Needs Servicing",
  },
  {
    label: "In-Service",
    value: "In-Service",
  },
  {
    label: "Out-of-Service",
    value: "Out-of-Service",
  },
  {
    label: "Dispensed",
    value: "Dispensed",
  },
  {
    label: "Partially Dispensed",
    value: "Partially Dispensed",
  },
  {
    label: "Inactive",
    value: "Inactive",
  },
  {
    label: "Destroyed",
    value: "Destroyed",
  },
  {
    label: "Damaged",
    value: "Damaged",
  },
  {
    label: "Repaired",
    value: "Repaired",
  },
  {
    label: "Disposed",
    value: "Disposed",
  },
  {
    label: "Expired",
    value: "Expired",
  },
  {
    label: "Recalled",
    value: "Recalled",
  },
  {
    label: "Returned",
    value: "Returned",
  },
  {
    label: "Stolen",
    value: "Stolen",
  },
  {
    label: "Unknown",
    value: "Unknown",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export let systemRoleOption = [

  {
    label: "Select Status",
    value: "",
  },
  {
    label: "Owner",
    value: "Owner",
  },
  {
    label: "Staff",
    value: "Staff",
  },
  {
    label: "Worker",
    value: "Worker",
  },
];
export let systemRoleOptionStaff = [

  {
    label: "Select Status",
    value: "",
  },
  {
    label: "Worker",
    value: "Worker",              
  },
];

export let systemRoleOptionOwner = [

  {
    label: "Select Status",
    value: "",
  },
  {
    label: "Staff",
    value: "Staff",
  },
  {
    label: "Worker",
    value: "Worker",
  },
];

export let selectVehicleStatus = [

  {
    label: "Select Status",
    value: "",
  },
  {
    label: "Reserve",
    value: "Reserve",
  },
  {
    label: "Out-of-Service",
    value: "Out-of-Service",
  },
  {
    label: "In-Service",
    value: "In-Service",

  },
];

export let providerRoleOption = [
  {
    label: "Select Status",
    value: "",
  },
  {
    label: "Paramedic",
    value: "Paramedic",
  },
  {
    label: "Physician",
    value: "Physician",
  },
  {
    label: "Registered Nurse",
    value: "Registered Nurse",
  },
  {
    label: "Pharmacy Technician",
    value: "Pharmacy Technician",
  },
  {
    label: "Pharmacist",
    value: "Pharmacist",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export let VehiclesColumns = [
  {
    header: "Image",
    accessor: "image",
    checked: true,
  },

  {
    header: "Name",
    accessor: "name",
    checked: true,
  },

  {
    header: "Unit ID",
    accessor: "vehicleUnitId",
    checked: true,
  },
  {
    header: "Radio ID",
    accessor: "radioId",
    checked: true,
  },
  {
    header: "Fleet",
    accessor: "vehicleFleet.name",
    checked: true,
  },
  {
    header: "License Plate",
    accessor: "licensePlateNumber",
    checked: true,
  },
  {
    header: "Type",
    accessor: "vehicleType",
    checked: true,
  },
  

  {
    header: "Vehicle Status",
    accessor: "vehicleStatus",
    checked: true,
  },
  {
    header: "Equipment Checklist",
    accessor: "equipmentChecklist.name",
    checked: true,
  },
  {
    header: "Total Qty",
    accessor: "tagCount",
    checked: true,
  },
  {
    header: "Vehicle Model",
    accessor: "vehicleModel",
    checked: true,
  },
  {
    header: "Model Year",
    accessor: "vehicleModelYear",
    checked: true,
  },
  {
    header: "Service Date",
    accessor: "serviceDate",
    type: 'date',
    checked: true,
  }
];

export let EquipmentChecklistsColumns =[

  {
    header: "Name",
    accessor: "name",
    checked: true
  },
  {
    header: "Description",
    accessor: "description",
    checked: true
  },
  {
    header: "Total Qty",
    accessor: "totalQty",
    checked: true
  },
  {
    header: "Assigned Vehicles",
    accessor: "assignedVehicles",
    checked: true
  },
  {
    header: "Report History Count",
    accessor: "historyCount",
    checked: true
  }
]

export let ProductColumns =[

  {
    header: "Name",
    accessor: "name",
    checked: true
  },
  {
    header: "ProductType",
    accessor: "productType",
    checked: true
  },
  {
    header: "Brand",
    accessor: "brand",
    checked: true
  },
  {
    header: "NDC",
    accessor: "ndc",
    checked: true
  },
  {
    header: "Manufacturer",
    accessor: "manufacturer",
    checked: true
  }
]

export let rowJson = [{
  name : "s",
  gender: "male",
  city:"bangalore",
  car:"car"
}]