import React from "react";
import ReactTable from "react-table-6";
import { Container, Button, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { saveAsPic } from "file-saver";
import AddAssetServiceHistory from "./AddAssetServiceHistory";
import EditAssetServiceHistory from "./EditAssetServiceHistory";
import { getDateTime } from "commonMethod/common";
import AssetItemServices from "service/AssetItemServices";
import "react-table-6/react-table.css";
import DataGrid, {
  Scrolling,
  ColumnChooser,
  Editing,
  Selection,
  Pager,
  Column,
  Paging,
  Toolbar,
  StateStoring,
  Button as GridButton,
  Item,
  HeaderFilter,
  SearchPanel,
  Export,
  FilterRow,
  LoadPanel,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterBuilderPopup,
  Summary,
  GroupItem,
} from "devextreme-react/data-grid";

import { jsPDF } from "jspdf";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Button as DxButton } from "devextreme-react/button";

const allowedPageSizes = [5, 10, 20, 25, 50, "all"];
const exportFormats = ["xlsx", "pdf"];
const filterBuilderPopupPosition = {
  of: window,
  at: "top",
  my: "top",
  offset: { y: 10 },
};
const filterPanelTexts = {
  clearFilter: "CLEAR FILTER"
};
class AssetServiceHistoryList extends React.Component {
  constructor(props) {
    super(props);
    this.servicehistoryGrid = React.createRef();
    this.state = {
      pageSize: 20,
      pageIndex: 0,
      itemTagId: "",
      show: false,
      editShow: false,
      searchKeyword: "",
      initialData: [],
      dropdownOpen: false,
      selectStartDate: "",
      selectEndDate: "",
      openAttachmentPopUp: false,
    };
    this.refreshDataGrid = this.refreshDataGrid.bind(this);
  }

  handleAttachmentsPopup = (e, id) => {
    this.setState({ editShow: !this.state.editShow, itemTagId: id });
    this.setState({
      editShow: !this.state.editShow,
      itemTagId: id,
      openAttachmentPopUp: true,
    });
  };

  toggleFilter = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  getAllServiceEventList = () => {
    const { asset_item_tag_id } = this.props;
    let obj = {
      itemId: asset_item_tag_id,
    };
    this.props.getAllServiceEventList(obj);
  };
  /**
   * Perform network request to get AssetServiceHistoryList data
   */
  componentDidMount() {
    this.getAllServiceEventList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.asset_id !== prevProps.asset_id) {
      this.getAllServiceEventList();
    }
  }

  toggleModal = () => {
    this.setState({ show: !this.state.show });
  };

  toggleEditModal = () => {
    this.setState({
      editShow: !this.state.editShow,
      openAttachmentPopUp: false,
    });
  };
  /**
   *  When user click on column this will sorting the table data
   * @param {*} sorted
   */
  onSortedChange = (sorted) => {
    Table.sort = sorted;
  };

  /**
   *  Return the selected pageSize
   * @param {*} pageSize
   * @param {*} pageIndex
   */

  onPageSizeChange = (pageSize, pageIndex) => {
    this.setState({ pageSize, pageIndex });
  };

  /**
   *  When user click on column this will sorting the table data
   * @param {*} sorted
   */
  onSortedChange = (sorted) => {
    AssetServiceHistoryList.sort = sorted;
  };

  /**
   * Call back for the page changes
   * @param {*} pageIndex
   */

  onPageChange = (pageIndex) => {
    this.setState({ pageIndex });
  };

  onEdit = (e, id) => {
    let { assetServiceHistoryList } = this.props;
    let editAttach = assetServiceHistoryList.filter((file) => file.GUID === id);
    this.setState({
      editShow: !this.state.editShow,
      itemTagId: id,
      openAttachmentPopUp: false,
    });
  };

  onDelete = (e, id) => {
    this.props.deleteAssetSeviceEvent(id).then((res) => {
      this.getAllServiceEventList();
    });
  };

  handleDownload = async (url, name) => {
    const blobImage = await fetch(url)
      .then((r) => r.blob())
      .catch((error) => console.log(error));

    if (blobImage) saveAsPic(blobImage, name);
  };
  getAssetItems = async (params = {}) => {
    const { pageSize, pageIndex } = this.state;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: "itemEPC",
      sortOrder: "ASC",
      id: this.props.asset_id,
      productType: "asset",
      ...params,
    };
  };

  onExporting(e) {
    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Companies");
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "MovementHistory.xlsx"
          );
        });
      });
      e.cancel = true;
    } else if (e.format === "pdf") {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
      }).then(() => {
        doc.save("MovementHistory.pdf");
      });
    }
  }
  refreshDataGrid() {
    this.servicehistoryGrid.current.instance.refresh();
    this.getAllServiceEventList();
  }

  render() {
    const columns = [
      {
        width: 230,
        Header: "Event Time",
        accessor: "eventTime",
        // Cell: ({ row }) => (<span>{row._original.eventTime && getDateTime(row._original.eventTime)}</span>)
        Cell: ({ row }) => {
          return (
            <span>
              {row._original.eventTime && getDateTime(row._original.eventTime)}
            </span>
          );
        },
      },
      {
        width: 200,
        Header: "Service Technician",
        accessor: "serviceTechnician",
      },
      {
        width: 200,
        Header: "Comments",
        accessor: "comments",
      },
      {
        width: 120,
        Header: "Cost",
        accessor: "cost",
      },
      {
        width: 200,
        Header: "Attachment",
        accessor: "attachments",
        Cell: ({ row }) => {
          return <span>{row.attachments.length}</span>;

          // return row.attachments.map((img) => (
          //   <span
          //     className="ml-3 text-primary"
          //     onClick={() => this.handleDownload(img.item, img.name)}>
          //     {img.name}
          //   </span>
          // ));
        },
      },
      {
        width: 120,
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <div className="drug-item-table-action">
            <i
              className="fas fa-edit text-info ml-3"
              onClick={(e) => this.onEdit(e, row._original.GUID)}
            ></i>{" "}
            <i
              className="fas fa-trash text-danger ml-3"
              onClick={(e) => this.onDelete(e, row._original.GUID)}
            ></i>{" "}
          </div>
        ),
      },
    ];

    const {
      asset_id,
      assetServiceHistoryList,
      updateAssetServiceEvent,
      updateAttachments,
      asset_item_tag_id,
      addAssetServiceEvent,
      getAllServiceEventList,
      getOneAssetServiceEvent,
    } = this.props;

    const {
      pageSize,
      pageIndex,
      show,
      itemTagId,
      editShow,
      openAttachmentPopUp,
    } = this.state;
    return (
      <>
        <div className="drug-detail-tag-title">
          {/* <Button
            className="addItemBtn"
            variant="primary"
            onClick={(e) => this.setState({ show: true })}
          >
            Add Service Event
          </Button> */}
        </div>
        {show && (
          <AddAssetServiceHistory
            asset_id={asset_item_tag_id}
            show={show}
            toggleModal={this.toggleModal}
            addAssetServiceEvent={addAssetServiceEvent}
            getAllServiceEventList={getAllServiceEventList}
            assetServiceHistoryList={assetServiceHistoryList}
            pageSize={pageSize}
            pageIndex={pageIndex}
          />
        )}

        <Container fluid className="table">
          <Row>
            <Col md="">
              {/* <ReactTable
                data={assetServiceHistoryList}
                columns={columns}
                onSortedChange={this.onSortedChange}
                defaultPageSize={pageSize}
                loadingText="Loading the drugs"
                noDataText="Couldn't find service event history data"
                onPageSizeChange={this.onPageSizeChange}
                onPageChange={this.onPageChange}
                onSortedChange={this.onSortedChange}
                getTrGroupProps={(state, rowInfo) => {
                  if (rowInfo !== undefined) {
                    return {
                      style: {
                        background: rowInfo.index % 2 ? "#fffff" : "#F5F5F5",
                      },
                      className: "table-row",
                    };
                  }
                }}
              /> */}

              <DataGrid
                id="girdServiceHistory"
                dataSource={assetServiceHistoryList}
                keyExpr="GUID"
                showBorders={false}
                rowAlternationEnabled={false}
                hoverStateEnabled={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode={"widget"}
                width={"100%"}
                onExporting={this.onExporting}
                ref={this.servicehistoryGrid}
              >
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey="asset-item-service-history-grid"
                />
                <FilterPanel visible={true} texts={filterPanelTexts} />
                <FilterBuilderPopup position={filterBuilderPopupPosition} />
                {/* <GroupPanel visible={true} /> */}
                {/* <Grouping autoExpandAll={true} contextMenuEnabled={true} /> */}
                <LoadPanel enabled />
                <FilterRow visible={false} applyFilter={"Immediately"} />
                {/* <Selection mode="multiple" /> */}
                <HeaderFilter visible={true} allowSearch={true} />
                <ColumnChooser enabled={true} mode={"select"} />
                <SearchPanel enabled={true} visible={true} width={300} />

                <Column
                  dataField="eventTime"
                  dataType="datetime"
                  caption="Event Time"
                  customizeText={(cellInfo) => {
                    return getDateTime(cellInfo.value) == "Invalid date"
                      ? ""
                      : getDateTime(cellInfo.value);
                  }}
                />
                <Column
                  dataField="serviceTechnician"
                  caption="Service Technician"
                />
                <Column dataField="comments" caption="Comments" />
                <Column dataField="cost" caption="Cost" alignment="left" />
                <Column
                  dataField="attachments.length"
                  caption="Attachments"
                  alignment="left"
                />
                <Column
                  width={110}
                  type="buttons"
                  // dataField="GUID"
                >
                  <GridButton
                    hint="edit"
                    icon="edit"
                    visible={true}
                    disabled={false}
                    onClick={(e) => {
                      this.onEdit(null, e.row.key);
                    }}
                  />
                  <GridButton
                    hint="delete"
                    icon="trash"
                    visible={true}
                    disabled={false}
                    onClick={(e) => {
                      this.onDelete(null, e.row.key);
                    }}
                  />
                </Column>
                <Summary>
                  <GroupItem
                    column="eventTime"
                    summaryType="count"
                    displayFormat="{0}"
                  />
                </Summary>
                <Export
                  enabled={true}
                  formats={exportFormats}
                  excelWrapTextEnabled={true}
                />
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={this.state.pageSize} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode={"adaptive"}
                  showPageSizeSelector={this.state.pageSize}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Toolbar>
                  <Item location="before" locateInMenu={"always"}>
                    <DxButton
                      text="Reset Grid"
                      hint="Reset Grid"
                      icon="revert"
                      onClick={() => {
                        this.servicehistoryGrid.current.instance.state(null);
                      }}
                    />
                  </Item>
                  {/* <Item name="groupPanel" location="before" /> */}
                  <Item location="after">
                    <Button
                      className="addItemBtn"
                      variant="primary"
                      onClick={(e) => this.setState({ show: true })}
                    >
                      Add Document(s)
                    </Button>
                  </Item>
                  <Item name="exportButton" location="after" />
                  <Item location="after">
                    <DxButton
                      hint="Refresh"
                      icon="refresh"
                      onClick={this.refreshDataGrid}
                    />
                  </Item>
                  <Item name="columnChooserButton" location="after" />
                  <Item name="searchPanel" location="before" />
                </Toolbar>
              </DataGrid>

              {/* {assetServiceHistoryList.length > 0 && (
                <Pagination
                  pageSize={pageSize}
                  currentPage={pageIndex + 1}
                  total={assetServiceHistoryList.length}
                />
              )} */}
            </Col>
          </Row>
          {this.state.editShow && (
            <EditAssetServiceHistory
              openAttachmentPopUp={openAttachmentPopUp}
              asset_id={asset_item_tag_id}
              itemTagId={itemTagId}
              editShow={editShow}
              toggleEditModal={this.toggleEditModal}
              updateAttachments={updateAttachments}
              updateAssetServiceEvent={updateAssetServiceEvent}
              getAllServiceEventList={getAllServiceEventList}
              getOneAssetServiceEvent={getOneAssetServiceEvent}
              pageSize={pageSize}
              pageIndex={pageIndex}
            />
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assetServiceHistoryList:
      state.assetSeviceHistoryListReducer.assetServiceHistoryList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllServiceEventList: (obj) =>
      dispatch(AssetItemServices.getAllServiceEventList(obj)),
    deleteAssetSeviceEvent: (id) =>
      dispatch(AssetItemServices.deleteAssetSeviceEvent(id)),
    addAssetServiceEvent: (formData) =>
      dispatch(AssetItemServices.addAssetServiceEvent(formData)),
    getOneAssetServiceEvent: (id) =>
      dispatch(AssetItemServices.getAssetServiceEventDetails(id)),
    updateAssetServiceEvent: (id, formData) =>
      dispatch(AssetItemServices.updateAssetServiceEvent(id, formData)),
    updateAttachments: (formData) =>
      dispatch(AssetItemServices.updateAttachments(formData)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssetServiceHistoryList)
);
