import React , {useState} from 'react';
import Paper from '@mui/material/Paper';
import { createStore } from 'redux';
import { connect, Provider } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Dropdown, DropdownButton, Row, Badge, Form } from "react-bootstrap";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  SortingState, SelectionState, FilteringState, PagingState, GroupingState, RowDetailState,
  IntegratedFiltering, IntegratedGrouping, IntegratedPaging, IntegratedSorting, DataTypeProvider, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid, Table, TableBandHeader, TableHeaderRow,
  TableFilterRow, TableSelection, TableGroupRow, TableRowDetail,
  GroupingPanel, PagingPanel, DragDropProvider, TableColumnReordering, TableColumnResizing, Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { styled } from '@mui/material/styles';
import { RowIndexer } from "../../container/EquipmentChecklists/row-indexer";
import Placeholder from '../../assets/img/placeholder-picture-image.png';
import { BaseURL } from '../../Constant/BaseURL';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {getDateTime, getShortDate} from "commonMethod/common";
import {millisToMinutesAndSeconds} from "commonMethod/common"


const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement='bottom-start' />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const LineitemUrl = ({ row, children }) => {
  const url = `${window.location.origin}/${row.productType}/${row.productGuid}`
  return <a href={url} target="_blank">{children}</a>
}

const columns = [
  {
    name: 'title', title: 'Name',
    getCellValue: row => (row.lineItemType === 'product' && row.image ?
      <LightTooltip title={row.productName}><span className='lineitemName' ><img src={`${BaseURL}/images/${row.productType}/${row.image}`} alt="" style={{ marginRight: "10px" }} srcset="" className="user-img-profile" /><LineitemUrl row={row}>{row.productName}</LineitemUrl></span></LightTooltip>
      : (row.lineItemType === 'product' && row.image === null) ?
        <LightTooltip title={row.productName}><span className='lineitemName' ><img src={Placeholder} alt="" style={{ marginRight: "10px" }} srcset="" className="user-img-profile" /><LineitemUrl row={row}>{row.productName}</LineitemUrl></span></LightTooltip>
        : (row.lineItemType === 'other' && row.image) ?
          <LightTooltip title={row.title}><span className='lineitemName'><img src={`${BaseURL}/images/${row.productType}/${row.image}`} alt="" style={{ marginRight: "10px" }} srcset="" className="user-img-profile" />{row.title}</span></LightTooltip>
          : <LightTooltip title={row.title}><span className='lineitemName'>{row.title}</span></LightTooltip>)
  },
  // {
  //   name:'title', title: 'Name' , getCellValue: row => (<span>{row.productName}</span>)
  // },
  {
    name: 'checkListFieldType', title: 'Type', getCellValue: row => (row.isCompleted === 1 ?
      <span className='check-line'><FormControlLabel
        control={<Checkbox defaultChecked={true} checked={true} color="default" />}
        label="Yes"
      /><Form.Check className='checklist-check' label="No" checked={false} /></span>
      :
      <span className='check-line'><Form.Check className='checklist-check' label="Yes" checked={false} /><FormControlLabel
        control={<Checkbox defaultChecked={true} checked={true} color="default" />}
        label="No"
      /></span>
    )
  },
  {
    name: 'comment', title: 'Remark', getCellValue: row => (
      <LightTooltip title={row.comment}>
        <span>{row.comment}</span>
      </LightTooltip>
    )
  },
  { name: 'qtyExpected', title: 'Qty Expected', getCellValue: row => (row.lineItemType === 'product' ? <span > <span className='line-qty'>Expected Qty :</span><span className='qty-value'> {row.qtyExpected}</span>  </span> : null) },
  { name: 'qtyRecorded', title: 'Qty On-Hand', getCellValue: row => (row.lineItemType === 'product' ? <span ><span className='line-qty'>On-Hand Qty :</span> <span className='qty-value'>{row.qtyRecorded}</span> </span> : null) },
  // { name: 'qtyRFIDTags', title: 'RFID' },
];

const sectionColumns = [
  { name: 'name', title: '', getCellValue: row => (<strong>{row.name}</strong>) },
  { name: 'checkListFieldType1', title: ' ', getCellValue: row => (<span></span>) },
  { name: 'checkListFieldType2', title: ' ', getCellValue: row => (<span></span>) },
  { name: 'qtyRecorded', title: 'On-Hand', getCellValue: row => (<span></span>) },
  { name: 'qtyExpected', title: 'Expected', getCellValue: row => (<span></span>) },
  { name: 'comment', title: ' ', getCellValue: row => (<span></span>) },
  { name: 'checkListFieldType', title: ' ', getCellValue: row => (<span></span>) },
]

const columnBands = [
  {
    title: 'Quantity',
    children: [
      { columnName: 'qtyExpected' },
      { columnName: 'qtyRecorded' },
    ],
  },
]

const ExpiryFormatter = ({ value }) => (
  
    value !== null ?  
  <span>{getShortDate(value.slice(0,10))}</span>
  :''
);

const ExpiryProvider = props => (
  <DataTypeProvider
    formatterComponent={ExpiryFormatter}
    {...props}
  />
);

const ManufactureFormatter = ({ value }) => (
  value !== null ?
  <span>{getShortDate(value)}</span>
  :''
);

const ManufactureProvider = props => (
  <DataTypeProvider
    formatterComponent={ManufactureFormatter}
    {...props}
  />
);

const PurchaseFormatter = ({ value }) => (
  value !== null ?
  <span>{getShortDate(value)}</span>
  :''
);

const PurchaseProvider = props => (
  <DataTypeProvider
    formatterComponent={PurchaseFormatter}
    {...props}
  />
);


const tableDetailColumnExtensions = [
  { columnName: 'index', width: 50 },
  { columnName: 'ndc', width: 170 },
  { columnName: 'serialNumber', width: 140 },
  { columnName: 'lotNumber', width: 140 },
  { columnName: 'expirationDate', width: 100 },
  { columnName: 'itemEPC', width: 220 },
];

const PREFIX = 'Demo';
export const classes = {
  detailContainer: `${PREFIX}-detailContainer`,
  title: `${PREFIX}-title`,
};
export const StyledDiv = styled('div')(({ theme }) => ({
  [`&.${classes.detailContainer}`]: {
    margin: '20px',
  },
  [`& .${classes.title}`]: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
  },
}));

export const GRID_STATE_CHANGE_ACTION = 'CHECKLIST_HISTORY_GRID_STATE_CHANGE_ACTION';

const ToggleCellComponent = ({ row, ...restProps }) => {
  if (Array.isArray(row.itemTags))
    return <TableRowDetail.ToggleCell row={row} {...restProps} />;
  return <td style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}></td>;
};


const TableHeaderContent = ({
  column, children, ...restProps
}) => (
  <TableHeaderRow.Content
    column={column}
    {...restProps}
  >
    {children}

  </TableHeaderRow.Content>
);

const GridItemTagsContainer = ({ row }) => {
  const [detailColumns] = useState([
    { name: 'index', title: '#' },
    { name: 'ndc', title: 'NDC' },
    { name: 'serialNumber', title: 'SERIAL NUMBER'},
    { name: 'lotNumber', title: 'LOT NUMBER' },
    { name: 'expirationDate', title: 'EXPIRY DATE' },
    { name: 'itemEPC', title: 'ITEM EPC' },
    {
      width: 150,
      title: 'ASSETID',
      name: 'assetId',
    },
    // {
    //   width: 150,
    //   title: 'MANUFACTURE DATE',
    //   name: 'manufactureDate',
    // },
    // {
    //   width: 150,
    //   title: 'VENDOR',
    //   name: 'vendor',
    // },
    // {
    //   width: 150,
    //   title: 'VENDOR NO',
    //   name: 'vendorNumber',
    // },
    // {
    //   width: 150,
    //   title: 'STATUS',
    //   name: 'status',
    // },
    // {
    //   width: 150,
    //   title: 'DEPARTMENT',
    //   name: 'department',
    // },
    // {
    //   width: 150,
    //   title: 'PURCHASE',
    //   name: 'purchaseDate',
    // },
    // {
    //   width: 150,
    //   title: 'LAST CHECK IN',
    //   name: 'lastCheckIn',
    // },
  ]);
  const [expiryColumns] = useState(['expirationDate']);
  const [manufactureColumns] = useState(['manufactureDate']);
  const [purchaseColumns] = useState(['purchaseDate']);
  return row.itemTags ?
    <StyledDiv className={classes.detailContainer}>
      <Paper>
        <Grid
          rows={row.itemTags}
          columns={detailColumns}
        >
          <ExpiryProvider
          for={expiryColumns}
        />
        <ManufactureProvider for={manufactureColumns}/>
        <PurchaseProvider for={purchaseColumns} />
          <RowIndexer />
          <Table
            columnExtensions={tableDetailColumnExtensions}
          />
          <TableHeaderRow />
        </Grid>
      </Paper>
    </StyledDiv>

    : null
};

const GridLineItemsContainer = ({
  row,
  sorting,
  onSortingChange,
  selection,
  onSelectionChange,
  filters,
  onFiltersChange,
  currentPage,
  onCurrentPageChange,
  pageSize,
  onPageSizeChange,
  pageSizes,
  columnOrder,
  onColumnOrderChange,
  columnWidths,
  onColumnWidthsChange,
}) => {

  const [grouping, setGrouping] = React.useState([])

  const [expandedGroups, setExpandedGroups] = React.useState([])

  const [expandedRowIds, setExpandedRowIds] = React.useState([])

  const onGroupingChange = React.useCallback((value) => {
    setGrouping(value)
  })

  const onExpandedGroupsChange = React.useCallback((value) => {
    setExpandedGroups(value)
  })

  const onExpandedRowIdsChange = React.useCallback((value) => {
    setExpandedRowIds(value)
  })

  return (

    <section className='lineItems-container'>
      <Paper>
        <Grid
          rows={row.lineItemResults}
          columns={columns}
        >
          <FilteringState
            filters={filters}
            onFiltersChange={onFiltersChange}
          />
          <SortingState
            sorting={sorting}
            onSortingChange={onSortingChange}
          />

          <GroupingState
            grouping={grouping}
            onGroupingChange={onGroupingChange}
            expandedGroups={expandedGroups}
            onExpandedGroupsChange={onExpandedGroupsChange}
          />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={onCurrentPageChange}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
          />

          <RowDetailState
            expandedRowIds={expandedRowIds}
            onExpandedRowIdsChange={onExpandedRowIdsChange}

          />
          <SelectionState
            selection={selection}
            onSelectionChange={onSelectionChange}
          />

          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedGrouping />
          <IntegratedPaging />
          <IntegratedSelection />

          <DragDropProvider />
          {/* <CellTooltip /> */}

          <Table />

          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={onColumnWidthsChange}
          />
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={onColumnOrderChange}
          />
          <TableRowDetail
            contentComponent={GridItemTagsContainer}
            toggleCellComponent={ToggleCellComponent}
          />


          <TableGroupRow />
          {/* <Toolbar /> */}
          {/* <GroupingPanel /> */}
        </Grid>

      </Paper>
    </section>
  )
};


export const createGridAction = (partialStateName, partialStateValue) => ({
  type: GRID_STATE_CHANGE_ACTION,
  partialStateName,
  partialStateValue,
});

const mapStateToProps = (state, ownProps) => {
  return { ...state.equipmentChecklistHistoryReducer.checklistHistoryGrid, ...ownProps }
};

const mapDispatchToProps = dispatch => ({
  onSortingChange: sorting => dispatch(createGridAction('sorting', sorting)),
  onSelectionChange: selection => dispatch(createGridAction('selection', selection)),
  onExpandedRowIdsChange: expandedRowIds => dispatch(createGridAction('expandedRowIds', expandedRowIds)),
  onGroupingChange: grouping => dispatch(createGridAction('grouping', grouping)),
  onExpandedGroupsChange: expandedGroups => dispatch(createGridAction('expandedGroups', expandedGroups)),
  onFiltersChange: filters => dispatch(createGridAction('filters', filters)),
  onCurrentPageChange: currentPage => dispatch(createGridAction('currentPage', currentPage)),
  onPageSizeChange: pageSize => dispatch(createGridAction('pageSize', pageSize)),
  onColumnOrderChange: order => dispatch(createGridAction('columnOrder', order)),
  onColumnWidthsChange: widths => dispatch(createGridAction('columnWidths', widths)),
});

const ReduxGridLineItemsContainer = connect(mapStateToProps, mapDispatchToProps)(GridLineItemsContainer);

const SectionsGridContainer = ({

  data = []
}) => {

  const [grouping, setGrouping] = React.useState([])

  const [expandedGroups, setExpandedGroups] = React.useState([])

  const [expandedRowIds, setExpandedRowIds] = React.useState([0])

  const onGroupingChange = React.useCallback((value) => {
    setGrouping(value)
  })

  const onExpandedGroupsChange = React.useCallback((value) => {
    setExpandedGroups(value)
  })

  const onExpandedRowIdsChange = React.useCallback((value) => {
    setExpandedRowIds(value)
  })

  React.useEffect(() => {
    setExpandedRowIds(data.map((d, index) => index))
  }, [data])

  return (
    <Paper>
      <Grid
        rows={data}
        //  columns={[{ name: 'name', title: 'Name' ,getCellValue: row => (<strong>{row.name}</strong> ) }]}
        columns={sectionColumns}
      >

        <GroupingState
          onGroupingChange={onGroupingChange}
          expandedGroups={expandedGroups}
          onExpandedGroupsChange={onExpandedGroupsChange}
        />

        <RowDetailState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={onExpandedRowIdsChange}
        />


        <IntegratedGrouping />


        <Table />
        {/* <TableHeaderRow contentComponent={TableHeaderContent} /> */}
        {/* <TableBandHeader
          columnBands={columnBands}
        /> */}
        <TableRowDetail
          contentComponent={ReduxGridLineItemsContainer}
        />

        <TableGroupRow />
        {/* <Toolbar /> */}
      </Grid>
    </Paper>
  )
}


export default SectionsGridContainer;

