
import { BiPencil } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { BsFillCaretDownFill }  from "react-icons/bs"
import { BsFillCaretUpFill }  from "react-icons/bs"
import IconButton from '@mui/material/IconButton';
import Placeholder from '../assets/img/placeholder-picture-image.png';
import { BaseURL } from '../Constant/BaseURL';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement='bottom-start' />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const LineitemUrl = ({ row, children }) => {
  const url = `${window.location.origin}/${row.productType}/${row.productGuid}`
  return <a href={url} target="_blank">{children}</a>
}

export let detailColumns = [
    { name: 'edit', title: (<BiPencil></BiPencil>) , getCellValue: row => (<span><BiPencil></BiPencil></span>) },
    { name: 'delete', title: (<MdDelete></MdDelete>) , getCellValue: row => (<span><MdDelete></MdDelete></span>)},
    { name: 'productName', title: 'LINE ITEM' },
    { name: 'otherName', title: 'TYPE' },
    { name: 'manufacturer', title: 'MANUFACTURER' },
    { name: 'dossage', title: 'SKU' },
    { name: 'modelNumber', title: 'PART NO.' },
    { name: 'brand', title: 'BRAND' },
  
  ];

  function actionRow ({ row, section, onEdit, onMove, onDelete }) {
    const actionRowStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    }

    return <span style={actionRowStyle}>
      <IconButton size="medium" onClick={() => { onEdit && onEdit(section, row)}}><BiPencil></BiPencil></IconButton>
      <IconButton size="medium" onClick={() => { onMove && onMove(section, row, 'UP')}}><BsFillCaretUpFill/></IconButton>
      <IconButton size="medium" onClick={() => { onMove && onMove(section, row, 'DOWN')}}><BsFillCaretDownFill/></IconButton>
      <IconButton size="medium" onClick={() => { onDelete && onDelete(section, row)}} ><MdDelete></MdDelete></IconButton>
    </span>

  }

  export function getDetailComuns ({ onDelete, onEdit, section, onMove }) {
    return [
      { name: 'xxx', title: ' ' , getCellValue: row => { return actionRow({row, onDelete, onEdit, section, onMove }) }},
      { name: 'productName', title: 'Line Item' ,  getCellValue: row => ( (row.productName === '')||(!row.productName) ? <LightTooltip title={row.title}><span style={{fontSize:"13px"}}><img src={Placeholder} alt="" style={{ marginRight: "10px" }} srcset="" className="user-img-profile" />{row.title}</span></LightTooltip> : ((!row.image)|| (row.image === '')|| (row.image === `${BaseURL}/images/${row.productType}/null`)) ?<LightTooltip title={row.productName}><span style={{fontSize:"13px"}}><img src={Placeholder} alt="" style={{ marginRight: "10px" }} srcset="" className="user-img-profile" /><LineitemUrl row={row}>{row.productName}</LineitemUrl></span></LightTooltip> : <LightTooltip title={row.productName}><span style={{fontSize:"13px"}}><img src={row.image} alt="" style={{ marginRight: "10px" }} srcset="" className="user-img-profile" /><LineitemUrl row={row}>{row.productName}</LineitemUrl></span></LightTooltip>)},
      { name: 'fieldType', title: 'Type', getCellValue: row => ( !row.qtyExpected  ? <span style={{fontSize:"13px"}}>{row.fieldType}</span> : <span style={{fontSize:"13px"}}>Quantity ({row.qtyExpected})</span>) },
      { name: 'productType', title: 'Product' },
      { name: 'manufacturer', title: 'Manufacturer' },
      { name: 'strength', title: 'Strength' },
      { name: 'ndc', title: 'NDC' },
      { name: 'UNSPSCCode', title: 'UNSPSC Code' },
      { name: 'productCode', title: 'EAN/UPC/GTIN/SKU' },
      { name: 'modelNumber', title: 'Model/Part No.' },
      { name: 'brand', title: 'Brand' },
      { name: 'netContent', title: 'Net Content' },
      { name: 'dossage', title: 'Dosage' },
      
    
    ]
    
  }

  export const getDetailComunsExtension = [
      { columnName: 'xxx', width: 200},
      { columnName: 'productName', width: 300},
      { columnName: 'fieldType', width: 130 },
      { columnName: 'manufacturer', width: 130},
      { columnName: 'productType', width: 130 },
      { columnName: 'ndc', width: 130 },
      { columnName: 'strength', width: 130 },
      { columnName: 'UNSPSCCode', width: 130 },
      { columnName: 'productCode', width: 130 },
      { columnName: 'modelNumber', width: 130 },
      { columnName: 'brand', width: 130 },
      { columnName: 'netContent', width: 130 },
      { columnName: 'dossage', width: 130 },
    ]


export function getSectionColumns ({ onDelete, onEdit, onMove }) {
  return [
    { name: 'xxx', title: ' ' , getCellValue: row => { return actionRow({ section: row, row: null, onDelete, onEdit, onMove }) }},
    { name: 'name' , title: 'Name'}
  ]
}

export const getSectionColumnsExtension = [
  { columnName: 'xxx', width: 180},
  { columnName: 'name', width: 130},
]

export let sectionColumns =[
    { name: 'name' , title: 'Name'}
]