import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import index from './styles/index';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0';
import './assets/css/demo.css';
// import "@fortawesome/fontawesome-free/css/all.min.css";
import './assets/fontawesome/v5/css/all.min.css';
import { Provider } from 'react-redux';
import AdminLayout from 'layouts/Admin.js';
import { store } from 'store/Store';
import Login from 'views/login/Login';
import AuthProvider from 'views/authProvider/authProvider';
import { useAuth } from 'views/authProvider/authProvider';
import 'devextreme/dist/css/dx.light.css';

function PrivateRoutes({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.token ? (
          <AdminLayout {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
/**
 * Entry point of the app
 */
ReactDOM.render(
  <Provider store={store}>
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path='/login' component={Login} />
          <PrivateRoutes path='/' />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  </Provider>,
  document.getElementById('root')
);
